import * as React from 'react';
import { useFormContext, type UseFormRegisterReturn } from 'react-hook-form';

import { Box } from '../Box';
import { Icon } from '../Icon';
import { ListItem, ListItemProps } from '../ListItem';

export type SelectableListProps = UseFormRegisterReturn & {
  items: (ListItemProps & { value: string })[];
};

export const SelectableList = React.forwardRef<
  HTMLInputElement,
  SelectableListProps
>(({ items, ...props }, ref) => {
  return (
    <Box flexDirection="column" gap="s2">
      {items.map(({ value, ...itemProps }) => (
        <SelectableListItem
          key={value}
          value={value}
          itemProps={itemProps}
          {...props}
          ref={ref}
        />
      ))}
    </Box>
  );
});

SelectableList.displayName = 'SelectableList';

export type SeletableListItemProps = UseFormRegisterReturn & {
  itemProps: ListItemProps;
  value: string;
};

export const SelectableListItem = React.forwardRef<
  HTMLInputElement,
  SeletableListItemProps
>(({ itemProps, value, ...props }, ref) => {
  const { watch } = useFormContext();

  const values: string[] = watch(props.name);
  const isChecked = values.find((v) => v === value);

  return (
    <Box
      as="label"
      htmlFor={`${props.name}-${value}`}
      style={{ cursor: 'pointer', width: '100%' }}
    >
      <ListItem
        {...itemProps}
        style={{ width: '100%' }}
        suffix={
          <Icon
            name="check"
            size="large"
            color="primary"
            style={{ opacity: isChecked ? 1 : 0 }}
          />
        }
      />
      <input
        type="checkbox"
        style={{ position: 'absolute', opacity: 0, width: 0, height: 0 }}
        value={value}
        id={`${props.name}-${value}`}
        {...props}
        ref={ref}
      />
    </Box>
  );
});

SelectableListItem.displayName = 'SelectableListItem';
