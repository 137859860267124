import { useCallback } from 'react';
import { formatNumber } from 'utils';

import type { NumberFormatProps } from '../components/NumberFormat';
import { SECRET_DISPLAY_VALUE } from '../constants/secrets';
import { useUIState } from '../providers/UIProvider';
import { formatHiddenContent } from '../utils/formatter';

export const useFormatUserNumber = () => {
  const {
    isSecretModeEnabled,
    locale: userLocale,
    currency: userCurrency,
  } = useUIState();

  const formatUserNumber = useCallback(
    (
      value: number | undefined | null,
      options?: Pick<
        NumberFormatProps,
        | 'currency'
        | 'locale'
        | 'type'
        | 'notSecret'
        | 'hideValue'
        | 'signDisplay'
        | 'notation'
      > & {
        digit?: number;
      }
    ) => {
      const {
        currency,
        digit = 0,
        locale,
        type = 'currency',
        notSecret,
        hideValue,
        signDisplay = 'never',
        notation,
      } = options ?? {};

      if (value === null || value === undefined || isNaN(value)) {
        return '-';
      }

      if (isSecretModeEnabled && !notSecret) {
        return SECRET_DISPLAY_VALUE;
      }

      const formattedValue = formatNumber(
        type === 'percent' ? value / 100 : value,
        locale ? locale : userLocale,
        {
          style: type,
          currency: currency ? currency : userCurrency,
          minimumFractionDigits:
            type === 'decimal' ? 0 : (value ?? 0) % 1 === 0 ? 0 : digit,
          signDisplay,
          maximumFractionDigits: digit,
          notation,
        }
      );

      return hideValue ? formatHiddenContent(formattedValue) : formattedValue;
    },
    [isSecretModeEnabled, userLocale, userCurrency]
  );

  return {
    formatUserNumber,
  };
};
