import React from 'react';

import { theme } from '../../styles/theme.css';
import { Box } from '../Box';
import { GaugeMainLine, GaugeValueIndicator } from '../Gauge';
import { Typography } from '../Typography';

export type PriceRangeProps = {
  average: number;
  max: number;
  min: number;
  value: number;
  disabled?: boolean;
  renderLabel?: (value: number) => React.ReactNode;
};

export const PriceRange = ({
  average,
  disabled,
  max,
  min,
  value,
  renderLabel,
}: PriceRangeProps) => {
  const minValue = disabled ? 0 : min;
  const maxValue = disabled ? 100 : max;
  const averageValue = disabled ? 50 : average;
  const currentValue = disabled ? 50 : value;

  const isLowerThanRange = currentValue < minValue;
  const isHigherThanRange = currentValue > maxValue;

  const averageValuePercentage =
    ((averageValue - minValue) * 100) / (maxValue - minValue);
  const valuePercentage =
    ((currentValue - minValue) * 100) / (maxValue - minValue);

  const lowerThanRangeContent = isLowerThanRange ? (
    <DashedBar
      direction="left"
      renderLabel={renderLabel}
      value={currentValue}
    />
  ) : null;

  const mainLine = (
    <Box flex={1} flexDirection="column" gap="s3">
      <GaugeMainLine disabled={disabled} />
      <Box alignItems="center" justifyContent="space-between">
        {renderLabel ? (
          <Typography variant="bodyXSmall" color="tertiary">
            {disabled ? '-' : renderLabel(minValue)}
          </Typography>
        ) : null}

        {renderLabel ? (
          <Typography variant="bodyXSmall" color="tertiary">
            {disabled ? '-' : renderLabel(maxValue)}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );

  const higherThanRangeContent = isHigherThanRange ? (
    <DashedBar
      direction="right"
      renderLabel={renderLabel}
      value={currentValue}
    />
  ) : null;

  return (
    <Box
      style={{
        position: 'relative',
      }}
    >
      {lowerThanRangeContent}
      {mainLine}
      {higherThanRangeContent}
      <AverageIndicator
        percentage={averageValuePercentage}
        disabled={disabled}
      />
      <GaugeValueIndicator percentage={valuePercentage} disabled={disabled} />
    </Box>
  );
};

const DashedBar = ({
  direction,
  value,
  renderLabel,
}: { direction: 'left' | 'right' } & Pick<
  PriceRangeProps,
  'value' | 'renderLabel'
>) => (
  <Box style={{ flex: 0.4 }} flexDirection="column" gap="s3">
    <Box
      style={{
        height: 2,
        border: `1px dashed ${theme.color.border.secondary}`,
      }}
    />
    <Box
      alignItems="center"
      justifyContent={direction === 'left' ? 'flex-start' : 'flex-end'}
    >
      {renderLabel ? (
        <Typography variant="bodyXSmall" color="tertiary">
          {renderLabel(value)}
        </Typography>
      ) : null}
    </Box>
  </Box>
);

const AverageIndicator = ({
  percentage,
  disabled,
}: {
  percentage: number;
  disabled?: boolean;
}) =>
  !disabled ? (
    <Box
      background="accentPrimary"
      style={{
        width: 1,
        height: 8,
        position: 'absolute',
        left: `calc(clamp(0%, ${percentage}%, 100%) - 1px)`,
        top: -3,
      }}
    />
  ) : null;
