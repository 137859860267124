import * as React from 'react';

import { Box, BoxProps } from '../Box';
import { Typography } from '../Typography';

import {
  chipToggleItemStyles,
  ChipToggleItemVariants,
} from './ChipToggleItem.css';

export type ChipToggleItemProps = ChipToggleItemVariants &
  Omit<BoxProps<'button'>, 'color'>;

export const ChipToggleItem = React.forwardRef<
  HTMLButtonElement,
  ChipToggleItemProps
>(({ children, icon, state, disabled, size, variant, ...buttonProps }, ref) => {
  return (
    <Box
      ref={ref}
      as="button"
      alignItems="center"
      justifyContent="center"
      type="button"
      className={chipToggleItemStyles({
        state,
        icon,
        disabled,
        size,
        variant,
      })}
      disabled={disabled}
      {...buttonProps}
    >
      <Typography variant="labelXSmall">{children}</Typography>
    </Box>
  );
});

ChipToggleItem.displayName = 'ChipToggleItem';
