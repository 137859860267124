import * as React from 'react';

import { radioStyle } from './Radio.css';

export type RadioProps = React.ComponentProps<'input'>;

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  ({ ...props }, ref) => {
    return <input {...props} ref={ref} type="radio" className={radioStyle} />;
  }
);

Radio.displayName = 'Radio';
