import * as React from 'react';

export type StepSideModalParamsList = Record<string, object | undefined>;

export type StepSideModalNavigateTo<T extends StepSideModalParamsList> = (
  key: keyof T | undefined,
  arg?: {
    description?: React.ReactNode;
    params?: T[keyof T];
    title?: React.ReactNode;
  }
) => void;

export const StepSideModalNavigationContext = React.createContext<
  StepSideModalNavigateTo<StepSideModalParamsList> | undefined
>(undefined);

export const StepSideModalNavigationProvider = <
  T extends StepSideModalParamsList
>({
  children,
  navigateTo,
}: {
  children: React.ReactNode;
  navigateTo: StepSideModalNavigateTo<T>;
}) => {
  return (
    <StepSideModalNavigationContext.Provider
      value={
        navigateTo as unknown as StepSideModalNavigateTo<StepSideModalParamsList>
      }
    >
      {children}
    </StepSideModalNavigationContext.Provider>
  );
};
