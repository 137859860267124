import { theme } from '../../styles/theme.css';
import { Box, BoxProps } from '../Box';

export type SeparatorProps = {
  variant?: 'vertical' | 'horizontal';
} & BoxProps;

export const Separator = ({
  variant = 'vertical',
  style,
  ...props
}: SeparatorProps) => {
  return (
    <Box
      style={{
        height: variant === 'horizontal' ? 1 : undefined,
        minHeight: variant === 'horizontal' ? 1 : undefined,
        width: variant === 'vertical' ? 1 : undefined,
        minWidth: variant === 'vertical' ? 1 : undefined,
        backgroundColor: theme.color.border.secondary,
        ...style,
      }}
      {...props}
    />
  );
};
