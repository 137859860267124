import { theme } from '../../styles/theme.css';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { NumberFormat } from '../NumberFormat';
import { ProgressBar } from '../ProgressBar';
import { Typography } from '../Typography';

export type ListItemWithGaugeProps = {
  label: string;
  percent: number;
  amount?: number;
  disabled?: boolean;
  labelPrefix?: React.ReactNode;
  labelSuffix?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const ListItemWithGauge = ({
  disabled,
  label,
  labelPrefix,
  labelSuffix,
  amount,
  percent,
  onClick,
}: ListItemWithGaugeProps) => {
  const labelContent = (
    <Box alignItems="center" flexDirection="row" gap="s3" flex={1}>
      {labelPrefix}
      <Box flex={1} gap="s2" alignItems="center">
        <Typography lineClamp={1} variant="bodyMedium" color="primary">
          {label}
        </Typography>

        {labelSuffix}
      </Box>
    </Box>
  );

  return (
    <Box
      flexDirection="row"
      onClick={disabled ? undefined : onClick}
      style={disabled ? undefined : { cursor: 'pointer' }}
      gap="s3"
      alignItems="center"
    >
      <Box flexDirection="column" flex={1}>
        <Box
          flexDirection="row"
          gap="s3"
          paddingVertical="s2"
          alignItems="center"
        >
          {labelContent}
          <Box justifyContent="flex-end">
            <NumberFormat
              value={percent}
              type="percent"
              digit={2}
              textVariant="bodyMedium"
              color="primary"
            />
          </Box>
        </Box>
        <Box flexDirection="row" gap="s2">
          <Box
            style={{ height: theme.spacing.s4 }}
            alignItems="center"
            flex={1}
          >
            <ProgressBar percent={percent} />
          </Box>
          {amount !== undefined ? (
            <Box style={{ width: '15%' }} justifyContent="flex-end">
              <NumberFormat
                type="currency"
                value={amount}
                textVariant="labelXSmall"
                color="tertiary"
              />
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box>
        {disabled ? null : (
          <Icon name="chevronRight" color="tertiary" size="medium" />
        )}
      </Box>
    </Box>
  );
};
