import { theme } from '../../styles/theme.css';

const CIRCLE_RADIUS = 7.5;
const CIRCLE_LENGTH = 2 * Math.PI * CIRCLE_RADIUS;

export type CircularProgressProps = {
  percent: number; // Should be between 0 and 100
};

export const CircularProgress = ({ percent }: CircularProgressProps) => {
  const circlePercent = (percent / 100) * CIRCLE_LENGTH;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r={CIRCLE_RADIUS}
        stroke={theme.color.border.secondary}
      />
      <circle
        cx="8"
        cy="8"
        r={CIRCLE_RADIUS}
        stroke={theme.color.border.selected}
        strokeDasharray={`${circlePercent} ${CIRCLE_LENGTH}`}
        transform="rotate(-90 8 8)"
      />
    </svg>
  );
};
