import * as React from 'react';

import { Icon } from '../Icon';

import { SelectField, SelectFieldProps } from './SelectField';

export const SelectFieldWithConfirmation = <T extends string>({
  onChange,
  ...props
}: SelectFieldProps<T>) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  return (
    <SelectField<T>
      // Troubles to type correctly the onChange because of the array/string generic
      onChange={async (selected: unknown) => {
        try {
          setIsLoading(true);
          await onChange?.(selected as T & T[]);
        } finally {
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
          }, 1000);
          setIsLoading(false);
        }
      }}
      suffix={
        isLoading ? (
          <Icon
            name="spinnerSolid"
            animation="rotate"
            color="tertiary"
            size="small"
          />
        ) : isSuccess ? (
          <Icon name="check" color="success" size="small" />
        ) : undefined
      }
      {...props}
    />
  );
};
