import * as React from 'react';

import { Box, BoxProps } from '../Box';
import {
  Typography,
  TypographyVariants,
  TypographyColors,
} from '../Typography';

import { TabsVariants, tabsStyle } from './Tabs.css';

export type TabsOption<T> = {
  id: T;
  label: React.ReactNode;
  disabled?: boolean;
};

export type TabsSize = 'small' | 'medium' | 'large' | 'xlarge';

export type TabsProps<T> = {
  active: T;
  onTabChange: (tab: T) => void;
  options: TabsOption<T>[];
  size?: TabsSize;
  suffix?: React.ReactNode;
  tabsContainerProps?: BoxProps;
} & TabsVariants &
  BoxProps;

const mapTypographyVariant = (size?: TabsSize): TypographyVariants => {
  switch (size) {
    case 'small':
      return 'labelSmall';
    case 'large':
      return 'headingSmall';
    case 'xlarge':
      return 'headingLarge';
    case 'medium':
    default:
      return 'labelMedium';
  }
};

const mapColor = (
  variant: NonNullable<TabsVariants>['variant'],
  isActive: boolean
): TypographyColors => {
  if (!isActive) {
    return undefined;
  }
  return variant === 'underlined' ? 'primary' : 'accentPrimary';
};

export const Tabs = <T extends string>({
  active,
  options,
  size,
  variant,
  onTabChange,
  tabsContainerProps,
  suffix,
  ...boxProps
}: TabsProps<T>) => {
  const tabId = React.useId();
  const tabsRef = React.useRef<Array<HTMLLIElement>>([]);
  const [activeMarkerStyle, setActiveMarkerStyle] = React.useState({
    transform: 'translateX(0px)',
    width: 0,
  });
  const isUnderlined = variant === 'underlined';
  const activeIndex = options.findIndex((tab) => tab.id === active);

  React.useEffect(() => {
    tabsRef.current = tabsRef.current.slice(0, options.length);
  }, [options.length]);

  React.useLayoutEffect(() => {
    if (isUnderlined) {
      setActiveMarkerStyle({
        transform: `translateX(${
          tabsRef?.current?.[activeIndex]?.offsetLeft ?? 0
        }px)`,
        width: tabsRef?.current?.[activeIndex]?.offsetWidth ?? 0,
      });
    }
  }, [setActiveMarkerStyle, activeIndex, isUnderlined, options]);

  return (
    <Box as="nav" style={{ width: '100%' }} {...boxProps}>
      <Box
        role="tablist"
        as="ol"
        className={tabsStyle.container({ variant })}
        {...tabsContainerProps}
      >
        {options.map((tab, i) => {
          const isActive = active === tab.id;
          const id = `${tabId}-${i}`;

          return (
            <Box
              aria-controls={id}
              aria-selected={isActive}
              as="li"
              className={tabsStyle.tab({ variant })}
              color="tertiary"
              id={id}
              key={id}
              onClick={tab.disabled ? undefined : () => onTabChange(tab.id)}
              ref={(el) => {
                if (el) {
                  tabsRef.current[i] = el;
                }
              }}
              role="tab"
              style={tab.disabled ? { cursor: 'default' } : undefined}
            >
              <Typography
                color={tab.disabled ? 'disabled' : mapColor(variant, isActive)}
                variant={mapTypographyVariant(size)}
              >
                {tab.label}
              </Typography>
            </Box>
          );
        })}
        {suffix ? <Box className={tabsStyle.suffix}>{suffix}</Box> : null}
        {isUnderlined ? (
          <Box className={tabsStyle.activeMarker} style={activeMarkerStyle} />
        ) : null}
      </Box>
    </Box>
  );
};
