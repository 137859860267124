import * as React from 'react';
import { isNullish } from 'utils';

import { Box, BoxProps } from '../Box';

import { Tabs, TabsProps, TabsOption } from './Tabs';

export type Tab<T extends string> = TabsOption<T> & {
  children: React.ReactNode;
};

export type TabbedViewPropsBase<T extends string> = Omit<
  TabsProps<T>,
  'options' | 'active' | 'onTabChange'
> & {
  tabs: Tab<T>[];
  containerProps?: BoxProps<'div'>;
  onTabChange?: (tab: T) => void;
};

export type TabbedViewControlledProps<T extends string> =
  TabbedViewPropsBase<T> & {
    active: T;
    defaultActive?: never;
  };

export type TabbedViewUncontrolledProps<T extends string> =
  TabbedViewPropsBase<T> & {
    active?: never;
    defaultActive?: T;
  };

export type TabbedProps<T extends string> =
  | TabbedViewControlledProps<T>
  | TabbedViewUncontrolledProps<T>;

export const TabbedView = <T extends string>({
  defaultActive,
  active,
  onTabChange,
  tabs,
  containerProps,
  ...tabsProps
}: TabbedProps<T>) => {
  const [uncontrolledActive, setUncontrolledActive] = React.useState<
    T | undefined
  >(() => {
    const defaultTab = tabs.find((tab) => tab.id === defaultActive);

    if (defaultTab) {
      return defaultTab.id;
    }

    return tabs[0]?.id;
  });
  const currentActive = active ?? uncontrolledActive;
  const tabChildren = tabs.find((tab) => tab.id === currentActive)?.children;

  if (isNullish(currentActive)) {
    throw new Error(
      'TabbedView: active, defaultActive or at least one tab is required'
    );
  }

  return (
    <Box flexDirection="column" {...containerProps}>
      <Tabs<T>
        active={currentActive}
        onTabChange={(tab) => {
          setUncontrolledActive(tab);
          onTabChange?.(tab);
        }}
        {...tabsProps}
        options={tabs}
      />
      {tabChildren}
    </Box>
  );
};
