import { useUIState } from '../../providers/UIProvider';
import { Box, BoxProps } from '../Box';
import { NumberFormat, NumberFormatProps } from '../NumberFormat';

export type MultiCurrencyProps = BoxProps & {
  displayValue: number | undefined | null;
  value: number | undefined | null;
  displayValueProps?: Omit<NumberFormatProps, 'value' | 'currency' | 'type'>;
  valueCurrencyCode?: string;
  valueProps?: Omit<NumberFormatProps, 'value' | 'currency' | 'type'>;
};

export const MultiCurrency = ({
  valueCurrencyCode,
  valueProps,
  displayValue,
  displayValueProps,
  value,
  ...props
}: MultiCurrencyProps) => {
  const { currency } = useUIState();

  return (
    <Box
      flexDirection="column"
      alignItems="flex-end"
      justifyContent="center"
      {...props}
    >
      <NumberFormat
        textVariant="bodyMedium"
        value={displayValue}
        currency={currency}
        {...displayValueProps}
      />
      {valueCurrencyCode !== currency ? (
        <NumberFormat
          textVariant="bodySmall"
          color="tertiary"
          value={value}
          currency={valueCurrencyCode}
          {...valueProps}
        />
      ) : null}
    </Box>
  );
};
