import classNames from 'classnames';
import * as React from 'react';
import { countDecimals } from 'utils/number';

import { useCombinedRefs } from '../../hooks/useCombinedRefs';
import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { NumberFormat } from '../NumberFormat';
import { ErrorMessage } from '../TextField';
import { Typography } from '../Typography';

import { ownershipFieldStyles } from './OwnershipField.css';

export type OwnershipFieldProps = Omit<
  React.ComponentPropsWithoutRef<'input'>,
  'prefix' | 'value'
> & {
  label: string;
  onDelete: () => unknown;
  onSubmit: () => unknown;
  error?: string;
  prefix?: React.ReactNode;
  totalAmount?: number;
  value?: number | string;
};

export const OwnershipField = React.forwardRef<
  HTMLInputElement,
  OwnershipFieldProps
>(
  (
    {
      prefix,
      onDelete,
      onSubmit,
      totalAmount,
      onFocus,
      onBlur,
      label,
      error,
      ...props
    },
    ref
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const inputId = React.useId();
    const combinedRef = useCombinedRefs(ref, inputRef);
    const [hasFocus, setHasFocus] = React.useState(false);

    const hasValue =
      (typeof props.value === 'number' && !Number.isNaN(props.value)) ||
      (typeof props.value === 'string' && props.value !== '');

    const valuePercentage =
      typeof props.value === 'string'
        ? parseFloat(props.value).toFixed(2)
        : (props.value ?? 0).toFixed(2);

    const showAddMode = !hasValue && !hasFocus;

    const getAmountPercentage = () => {
      switch (typeof props.value) {
        case 'undefined':
          return 1;
        case 'string':
          if (props.value === '') {
            return 1;
          }
          return parseFloat(props.value) / 100;
        default:
          return props.value / 100;
      }
    };

    const renderAmount = () => {
      if (typeof totalAmount !== 'number') {
        return null;
      }

      return (
        <>
          {' • '}
          <NumberFormat
            showZeroValue
            value={totalAmount * getAmountPercentage()}
          />
        </>
      );
    };

    const renderIconButton = () => {
      if (showAddMode) {
        return (
          <IconButton<'button'>
            type="button"
            icon="plus"
            variant="quaternary"
            size="medium"
            onMouseDown={(event) => {
              event.preventDefault();
              inputRef.current?.focus();
            }}
          />
        );
      }
      if (hasFocus) {
        return (
          <IconButton
            type="button"
            disabled={!!error || !hasValue}
            icon="check"
            variant="quaternary"
            size="medium"
          />
        );
      }

      return (
        <IconButton
          type="button"
          icon="close"
          variant="quaternary"
          size="medium"
          onMouseDown={onDelete}
        />
      );
    };

    return (
      <Box flexDirection="column">
        <Box
          as="label"
          htmlFor={inputId}
          className={ownershipFieldStyles.root({
            error: !!error,
            focused: hasFocus,
          })}
        >
          <Box alignItems="center" paddingVertical="s1" gap="s5" flex={1}>
            {prefix}
            <Box flex={1} flexDirection="column" style={{ height: 44 }}>
              <Typography
                lineClamp={1}
                className={classNames(ownershipFieldStyles.label, {
                  [ownershipFieldStyles.labelActive]: hasFocus,
                  [ownershipFieldStyles.labelActiveWithValue]:
                    hasValue && !hasFocus,
                })}
              >
                {label}
              </Typography>
              <Box
                position="relative"
                style={{
                  width: '100%',
                  opacity: showAddMode ? 0 : 1,
                }}
              >
                <input
                  id={inputId}
                  ref={combinedRef}
                  className={ownershipFieldStyles.input}
                  style={{ height: !hasFocus ? 0 : 'auto' }}
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.currentTarget.blur();
                    }
                  }}
                  onFocus={(event) => {
                    onFocus?.(event);
                    setHasFocus(true);
                  }}
                  onBlur={(event) => {
                    onBlur?.(event);
                    onSubmit();
                    setHasFocus(false);
                    event.stopPropagation();
                  }}
                  placeholder="100"
                  {...props}
                  onChange={(event) => {
                    if (countDecimals(event.target.value) > 2) {
                      event.target.value = parseFloat(
                        event.target.value
                      ).toFixed(2);
                    }

                    return props.onChange?.(event);
                  }}
                />
                {!hasFocus ? (
                  <Typography
                    position="absolute"
                    flex={1}
                    flexShrink={0}
                    variant="bodyMedium"
                    color="tertiary"
                  >
                    {valuePercentage} %{renderAmount()}
                  </Typography>
                ) : (
                  <Typography
                    flexShrink={0}
                    position="absolute"
                    style={{ right: 0 }}
                    variant="bodyLarge"
                    color="disabled"
                  >
                    %{renderAmount()}
                  </Typography>
                )}
              </Box>
            </Box>

            {renderIconButton()}
          </Box>
        </Box>
        {error ? <ErrorMessage message={error} /> : null}
      </Box>
    );
  }
);

OwnershipField.displayName = 'OwnershipField';
