import { rgba } from 'polished';

import { palette, theme } from '../../styles/theme.css';
import { Box } from '../Box';
import { Typography } from '../Typography';

export type GaugeProps = {
  percentage: number;
  color?: string;
  disabled?: boolean;
  legendEnd?: string;
  legendStart?: string;
};

export const Gauge = ({
  disabled,
  percentage,
  color,
  legendStart,
  legendEnd,
}: GaugeProps) => {
  return (
    <Box
      flexDirection="column"
      flex={1}
      borderRadius="large"
      style={{ position: 'relative' }}
    >
      <GaugeMainLine disabled={disabled} color={color} />
      <GaugeValueIndicator percentage={percentage} disabled={disabled} />
      {legendStart || legendEnd ? (
        <Box alignItems="center" justifyContent="space-between" marginTop="s2">
          <Typography variant="labelTag" color="tertiary">
            {legendStart}
          </Typography>
          <Typography variant="labelTag" color="tertiary">
            {legendEnd}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export type GaugeMainLineProps = {
  color?: string;
  disabled?: boolean;
};

export const GaugeMainLine = ({ disabled, color }: GaugeMainLineProps) => {
  return (
    <Box
      style={{
        height: 2,
        width: '100%',
        background: disabled
          ? theme.color.surface.tertiary
          : `linear-gradient(90deg, ${
              color ? rgba(color, 0.1) : theme.color.range.start
            } 0%, ${color ?? theme.color.range.end} 100%)`,
      }}
    />
  );
};

export type GaugeValueIndicatorProps = {
  percentage: number;
  disabled?: boolean;
};

export const GaugeValueIndicator = ({
  percentage,
  disabled,
}: GaugeValueIndicatorProps) => {
  return (
    <Box
      borderRadius="full"
      style={{
        width: 8,
        height: 8,
        background: disabled
          ? theme.color.surface.tertiary
          : palette.neutralWhite,
        position: 'absolute',
        left: `calc(clamp(0%, ${percentage}%, 100%) - 4px)`,
        top: -3,
        outlineOffset: 0,
        outline: `4px solid ${theme.color.surface.disabled}`,
      }}
    />
  );
};
