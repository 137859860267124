import * as React from 'react';

import { useCopyValue } from '../../hooks/useCopyValue';
import { Button, ButtonProps } from '../Button';
import { CopyIcon } from '../CopyIcon';

export type CopyButtonProps = React.ComponentPropsWithoutRef<'button'> &
  React.PropsWithChildren<ButtonProps> & {
    valueToCopy: string;
  };

export const CopyButton = ({
  valueToCopy,
  children,
  onClick,
  ...buttonProps
}: CopyButtonProps) => {
  const { feedback, handleCopyValue, lastFeedback } = useCopyValue(valueToCopy);

  return (
    <Button
      {...buttonProps}
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClick?.(event);
        handleCopyValue(event);
      }}
    >
      <CopyIcon feedback={feedback} lastFeedback={lastFeedback} />
      {children}
    </Button>
  );
};
