import * as React from 'react';
import { countDecimals } from 'utils';

import { useFormatUserNumber } from '../../hooks/useFormatUserNumber';
import { Box, BoxProps } from '../Box';
import {
  Typography,
  TypographyColors,
  TypographyVariants,
} from '../Typography';

export type NumberFormatType = 'decimal' | 'currency' | 'percent';

export type NumberFormatProps = {
  colorBasedOnSign?: boolean;
  currency?: string;
  digit?: number | 'auto';
  /**
   *  Hide value by displaying a fake and blured one
   */
  hideValue?: boolean;
  locale?: string;
  notSecret?: boolean;
  notation?: Intl.NumberFormatOptions['notation'];
  showZeroValue?: boolean;
  signDisplay?: Intl.NumberFormatOptions['signDisplay'];
  suffix?: React.ReactNode;
  textColor?: TypographyColors;
  textVariant?: TypographyVariants;
  type?: NumberFormatType;
  value?: number | null;
} & BoxProps;

const getColor = (value: NumberFormatProps['value']): TypographyColors => {
  if (typeof value !== 'number') {
    return undefined;
  }

  if (value > 0) {
    return 'success';
  }
  if (value < 0) {
    return 'error';
  }

  return undefined;
};

export const NumberFormat = ({
  type = 'currency',
  value,
  digit = 0,
  textVariant,
  textColor,
  notSecret = false,
  locale,
  currency,
  showZeroValue,
  suffix,
  hideValue,
  filter,
  signDisplay = 'auto',
  notation,
  colorBasedOnSign = false,
  ...props
}: NumberFormatProps) => {
  const { formatUserNumber } = useFormatUserNumber();

  const digitsNumber = digit === 'auto' ? countDecimals(value ?? 0) : digit;

  const precision = digit ? Math.pow(10, digitsNumber) : 1;
  // the normalized value is rounded, so we always display 0
  // with the same color + sign (for instance when digits is 2, but the value
  // is 0.001 or -0.001)
  const normalizedValue =
    value || (showZeroValue && value === 0)
      ? digitsNumber
        ? Math.round(value * precision) / precision
        : Number(value)
      : undefined;

  const color =
    textColor ?? (colorBasedOnSign ? getColor(normalizedValue) : undefined);

  const formattedValue = React.useMemo(
    () =>
      formatUserNumber(normalizedValue, {
        type,
        currency,
        locale,
        digit: digitsNumber,
        notSecret,
        hideValue,
        signDisplay,
        notation,
      }),
    [
      normalizedValue,
      type,
      locale,
      currency,
      digitsNumber,
      formatUserNumber,
      notSecret,
      hideValue,
      signDisplay,
      notation,
    ]
  );

  return (
    <Box
      display="inline-flex"
      filter={hideValue ? 'blurSmall' : filter}
      {...props}
      as="span"
    >
      <Typography color={color} variant={textVariant} whiteSpace="nowrap">
        {formattedValue}
        {suffix}
      </Typography>
    </Box>
  );
};
