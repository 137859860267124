import { AssetLogo, AssetLogoProps } from '../AssetLogo';
import { Box, BoxProps } from '../Box';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

import { tileStyles } from './Tile.css';

export type TileProps<C extends React.ElementType = 'div'> = BoxProps<C> & {
  assetLogoProps?: AssetLogoProps;
  description?: string;
  label?: string;
  title?: string;
};

export const Tile = <C extends React.ElementType = 'div'>({
  description,
  label,
  title,
  assetLogoProps,
  ...props
}: TileProps<C>) => {
  const variant =
    'href' in props || 'onClick' in props ? 'clickable' : 'default';

  return (
    <Box<React.ElementType<unknown>>
      alignItems="center"
      backgroundColor="primary"
      borderRadius="medium"
      padding="s5"
      gap="s4"
      className={tileStyles({ variant })}
      {...props}
    >
      <AssetLogo {...assetLogoProps} />
      <Box flexDirection="column" gap="s2" flex={1}>
        {label ? (
          <Typography variant="bodyMedium" color="tertiary">
            {label}
          </Typography>
        ) : null}
        <Typography variant="labelMedium" color="primary">
          {title}
        </Typography>
        <Typography variant="bodyMedium" color="secondary">
          {description}
        </Typography>
      </Box>
      {variant === 'clickable' ? (
        <Icon name="chevronRight" color="secondary" />
      ) : null}
    </Box>
  );
};
