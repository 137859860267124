import { SVGProps } from 'react';

export const FinaryStandaloneLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 168 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56 0C25.0721 0 0 25.0721 0 56H112C142.928 56 168 30.9279 168 0H56ZM56 70C25.0721 70 0 95.0721 0 126H52C82.9279 126 108 100.928 108 70H56Z"
        fill="#F1C086"
      />
    </svg>
  );
};
