import * as React from 'react';

/**
 * To use groups in a Table, you have to provide the table with 3 things:
 * - `getDatumKey`: a function that returns a unique key for a given datum
 * - `getRowChildren`: a function that returns a given datum's children if any
 * - `openGroups`: a Map representing the open / close state of groups
 *
 * This function takes both the `getDatumKey` & `getRowChildren` functions you
 * provide to the Table, and returns the `openGroups` state, as well as
 * - toggleGroupOpen: which allows you to toggle the state for a given datum
 * - resetGroups: in case you need to reset groups
 *
 * @param getDatumKey the function used in the Table component to get a unique
 *                    key for a given datum
 * @param getRowChildren the function used in the Table component to return
 *                       a given datum children if any
 */
export const useTableGroups = <T extends string, Datum>(
  getDatumKey: ({ datum }: { datum: Datum }) => T,
  getRowChildren: ({ datum }: { datum: Datum }) => Datum[] | undefined,
  defaultOpenGroups?: Map<T, boolean>
) => {
  const [openGroups, setOpenGroups] = React.useState<Map<T, boolean>>(
    defaultOpenGroups ?? new Map()
  );

  /* If the defaultOpenGroups change, we update the state
   * This is needed to update the open groups after data loads
   */
  React.useEffect(() => {
    if (defaultOpenGroups) {
      setOpenGroups(defaultOpenGroups);
    }
  }, [defaultOpenGroups]);

  const toggleGroupOpen = React.useCallback(
    (datum: Datum, newOpen?: boolean) => {
      const children = getRowChildren({ datum });
      const isWithChildren = !!children?.length;
      if (isWithChildren) {
        const key = getDatumKey({ datum });
        const open = openGroups.get(key);
        openGroups.set(key, newOpen ?? !open);
        setOpenGroups(new Map(openGroups));
      }
    },
    [getDatumKey, getRowChildren, openGroups]
  );

  const resetGroups = React.useCallback(() => setOpenGroups(new Map()), []);

  return { openGroups, resetGroups, toggleGroupOpen };
};
