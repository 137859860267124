import classNames from 'classnames';

import { sprinkles } from '../../styles/sprinkles.css';
import { Box, BoxProps } from '../Box';
import { Icon, IconName } from '../Icon';
import { Typography, TypographyColors } from '../Typography';

import { tagStyles, TagVariant } from './Tag.css';

export type Size = Exclude<TagVariant, undefined>['size'];

export type Variants = Exclude<
  Exclude<TagVariant, undefined>['variant'],
  'custom'
>;

export type CustomVariant = Extract<
  Exclude<TagVariant, undefined>['variant'],
  'custom'
>;

export type TagBaseProps = Omit<
  BoxProps<'div' | 'button'>,
  'prefix' | 'title'
> & {
  prefixIcon?: IconName;
  size?: Size;
  suffixIcon?: IconName;
};

export type TagWithoutColor = TagBaseProps & {
  color?: never;
  variant?: Variants;
};

export type TagWithColor = TagBaseProps & {
  color?: TypographyColors;
  variant?: CustomVariant;
};

export type TagProps = TagWithoutColor | TagWithColor;

export const Tag = ({
  children,
  size = 'medium',
  variant,
  prefixIcon,
  suffixIcon,
  className,
  color,
  ...props
}: TagProps) => {
  return (
    <Box
      className={classNames(
        tagStyles.container({ size, variant }),
        sprinkles({ color }),
        className
      )}
      {...props}
    >
      {prefixIcon ? <Icon name={prefixIcon} size="small" /> : null}
      <Typography
        className={tagStyles.text({ size })}
        variant="labelTag"
        lineClamp={1}
        showTooltipWhenOverflowing
      >
        {children}
      </Typography>
      {suffixIcon ? <Icon name={suffixIcon} size="small" /> : null}
    </Box>
  );
};

export const FinaryPlusTag = () => (
  <Tag variant="finaryPlus" prefixIcon="finary-mark">
    Plus
  </Tag>
);
