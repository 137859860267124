import type { DistributiveOmit } from 'utils';

import {
  AssetLogo,
  AssetLogoLoader,
  AssetLogoProps,
} from '../AssetLogo/AssetLogo';
import { Avatar } from '../Avatar';
import { Box } from '../Box';

import { assetLogoGroupStyles } from './AssetLogoGroup.css';

const MAP_SIZE_TO_REMAINING_TOTAL_SIZE = {
  xxxxlarge: 'xlarge',
  xxxlarge: 'xlarge',
  xxlarge: 'xlarge',
  xlarge: 'medium',
  large: 'small',
  medium: 'xsmall',
  small: 'xsmall',
} as const;

export type AssetLogoGroupProps = {
  assetLogos: DistributiveOmit<AssetLogoProps, 'size'>[];
  getMoreLabel?: (count: number) => string;
  maxDisplayedAssets?: number;
  size?: AssetLogoProps['size'];
};

export const AssetLogoGroup = ({
  assetLogos,
  getMoreLabel,
  maxDisplayedAssets = 3,
  size = 'large',
}: AssetLogoGroupProps) => {
  const cappedAssetLogos =
    assetLogos.length > maxDisplayedAssets
      ? assetLogos.slice(0, maxDisplayedAssets - 1)
      : assetLogos;
  const remainingAssetLogos = assetLogos.slice(cappedAssetLogos.length);
  const remainingTotal = remainingAssetLogos.length;

  if (!assetLogos.length) {
    return null;
  }

  return (
    <Box display="inline-flex" className={assetLogoGroupStyles}>
      {cappedAssetLogos.map((assetLogo, i) => (
        <AssetLogo key={i} size={size} {...assetLogo} />
      ))}
      {remainingTotal ? (
        <Avatar
          size={MAP_SIZE_TO_REMAINING_TOTAL_SIZE[size]}
          initials={`+${remainingTotal}`}
          name={getMoreLabel?.(remainingTotal)}
        />
      ) : null}
    </Box>
  );
};

export type AssetLogoGroupLoaderProps = {
  numberOfLogos: number;
  size: AssetLogoProps['size'];
};

export const AssetLogoGroupLoader = ({
  numberOfLogos,
  size,
}: AssetLogoGroupLoaderProps) => {
  return (
    <Box display="inline-flex" className={assetLogoGroupStyles}>
      {[...new Array(numberOfLogos)].map((_, i) => (
        <AssetLogoLoader key={i} size={size} />
      ))}
    </Box>
  );
};
