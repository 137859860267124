import classNames from 'classnames';
import * as React from 'react';

import { Box } from '../Box';
import { Typography } from '../Typography';

import { toggleStyles } from './Toggle.css';

export type ToggleProps = {
  onChange: (value: boolean) => void;
  value: boolean | undefined;
  disabled?: boolean;
  grow?: boolean;
  label?: React.ReactNode;
  labelBefore?: boolean;
};

export const Toggle = ({
  onChange,
  value,
  label,
  labelBefore,
  grow,
  disabled,
}: ToggleProps) => {
  const id = React.useId();

  return (
    <Box
      alignItems="center"
      alignSelf="flex-start"
      gap="s3"
      as="label"
      htmlFor={id}
      flexGrow={grow ? 1 : undefined}
      justifyContent={grow ? 'space-between' : undefined}
      style={{ cursor: disabled ? 'default' : 'pointer' }}
    >
      {label ? (
        <Typography
          variant="bodyMedium"
          color="primary"
          order={labelBefore ? 0 : 1}
        >
          {label}
        </Typography>
      ) : null}
      <Box
        className={classNames(toggleStyles.root, {
          [toggleStyles.rootDisabled]: disabled,
        })}
        data-checked={value}
      >
        <input
          disabled={disabled}
          id={id}
          checked={value}
          type="checkbox"
          className={toggleStyles.input}
          onChange={() => onChange(!value)}
        />
        <Box className={toggleStyles.thumb} />
      </Box>
    </Box>
  );
};
