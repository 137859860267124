import * as React from 'react';

import { Box } from '../Box';
import { Icon, IconName } from '../Icon';
import { Typography } from '../Typography';

import { statusStyle, StatusSize, StatusVariant } from './Status.css';

export type StatusProps = { size?: StatusSize } & (
  | {
      text?: never;
      variant?: Extract<
        StatusVariant,
        'success' | 'error' | 'warning' | 'unknown' | 'default'
      >;
    }
  | {
      text: string | React.ReactNode;
      variant: Extract<StatusVariant, 'textPrimary' | 'textSecondary'>;
    }
);

const mapIcon: (variant: StatusVariant) => IconName = (variant) => {
  switch (variant) {
    case 'success':
      return 'check';
    case 'warning':
      return 'exclamationCircle';
    case 'error':
      return 'exclamationTriangle';
    case 'unknown':
      return 'questionSquare';
    default:
      return 'finary-mark';
  }
};

export const Status = ({ variant, size, text }: StatusProps) => {
  return (
    // The extra container is needed for the "textSecondary" variant as we need a gradient text
    <Box className={statusStyle.container({ size })}>
      <Box
        aria-label={variant ?? 'default'}
        className={statusStyle.content({ variant })}
      >
        {!!text ? (
          <Typography variant={size === 'large' ? 'labelCaption' : 'labelTag'}>
            {text}
          </Typography>
        ) : (
          <Icon size={size} name={mapIcon(variant)} />
        )}
      </Box>
    </Box>
  );
};
