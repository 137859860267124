/**
 * Returns a css transition string for the given css properties
 * @param properties - the css properties
 * @param duration - the css duration
 * @param timing - the timing function
 */
export const createTransition = (
  properties: React.CSSProperties['transitionProperty'][],
  duration: React.CSSProperties['transitionDuration'],
  timing: React.CSSProperties['transitionTimingFunction']
) => {
  return properties
    .map((property) => `${property} ${duration} ${timing}`)
    .join(', ');
};
