import * as React from 'react';

import {
  IndicatorPosition,
  useSelectedIndicator,
} from '../../hooks/useSelectedIndicator';
import { isElementType } from '../../utils/react';
import { cssVariable } from '../../utils/styles';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { SelectOption } from '../Select';

import {
  chipToggleStyles,
  indicatorHeight,
  indicatorTransitionDuration,
  indicatorWidth,
  indicatorX,
  indicatorY,
} from './ChipToggle.css';
import { ChipToggleItem } from './ChipToggleItem';
import { ChipToggleItemVariants } from './ChipToggleItem.css';

export type ChipToggleProps<T> = {
  options: SelectOption<T>[];
  defaultValue?: T;
  disabled?: boolean;
  fullWidth?: boolean;
  onChange?: (selected: T) => void;
  size?: Exclude<ChipToggleItemVariants, undefined>['size'];
  value?: T;
  variant?: Exclude<ChipToggleItemVariants, undefined>['variant'];
};

export const ChipToggle = <T,>({
  options,
  defaultValue,
  onChange,
  disabled,
  value,
  fullWidth,
  variant,
  size,
}: ChipToggleProps<T>) => {
  const initialState = value ?? defaultValue;
  const [selectedState, setSelectedState] = React.useState<T | undefined>(
    initialState
  );
  const selected = value ?? selectedState;

  if (defaultValue && value) {
    throw new Error(
      'ChipToggle Component should be controlled or uncontrolled, please specify `defaultValue`, or `value`, but not both'
    );
  }

  const containerRef = React.useRef<HTMLDivElement>(null);

  // Return the chip element matching the given value
  const getElementByValue = React.useCallback(
    (value?: T) => {
      const parent = containerRef.current;
      const selectedIndex = options.findIndex(
        (option) => option.value === value
      );
      return (
        parent?.querySelector(`:scope > :nth-child(${selectedIndex + 1})`) ??
        null
      );
    },
    [options]
  );

  // Toggle transitions on / off for the selected indicator
  const setIndicatorAnimation = React.useCallback((animate: boolean) => {
    const parent = containerRef.current;
    if (animate) {
      parent?.style.removeProperty(cssVariable(indicatorTransitionDuration));
    } else {
      parent?.style.setProperty(cssVariable(indicatorTransitionDuration), '0s');
    }
  }, []);

  // Position the indicator
  const setIndicatorPosition = React.useCallback(
    (position: IndicatorPosition) => {
      const parent = containerRef.current;
      if (parent) {
        const style = parent.style;
        style.setProperty(cssVariable(indicatorX), `${position.x}px`);
        style.setProperty(cssVariable(indicatorY), `${position.y}px`);
        style.setProperty(cssVariable(indicatorWidth), `${position.width}px`);
        style.setProperty(cssVariable(indicatorHeight), `${position.height}px`);
      }
    },
    [containerRef]
  );

  const updateIndicator = useSelectedIndicator(
    containerRef,
    getElementByValue,
    setIndicatorAnimation,
    setIndicatorPosition,
    initialState,
    selected
  );

  return (
    <Box
      ref={containerRef}
      className={chipToggleStyles({ disabled, variant })}
      borderRadius="full"
      padding="s1"
      flex={fullWidth ? 1 : undefined}
    >
      {options.map((option) => (
        <ChipToggleItem
          size={size}
          variant={variant}
          icon={isElementType(option.label, Icon)}
          onClick={() => {
            if (!disabled) {
              const optionIndex = options.findIndex(
                (opt) => opt.value === option.value
              );
              const newSelectedOption =
                selected === option.value && options.length === 2
                  ? options[(optionIndex + 1) % options.length] ?? option
                  : option;
              const selectedChip = getElementByValue(newSelectedOption.value);
              let isFocusVisible = false;
              try {
                isFocusVisible =
                  !!selectedChip?.parentElement?.querySelector(
                    ':focus-visible'
                  );
              } catch (e) {
                // ignore - related to https://github.com/jsdom/jsdom/issues/3055
              }
              if (selectedChip) {
                updateIndicator(selectedChip);
                if (isFocusVisible && selectedChip instanceof HTMLElement) {
                  selectedChip.focus();
                }
              }
              setSelectedState(newSelectedOption.value);
              onChange?.(newSelectedOption.value);
            }
          }}
          key={option.key}
          disabled={disabled}
          flex={fullWidth ? 1 : undefined}
        >
          {option.label}
        </ChipToggleItem>
      ))}
    </Box>
  );
};
