import { StyleRule } from '@vanilla-extract/css';

import { Breakpoint, breakpoints } from '../styles/breakpoints';

/**
 * Returns the variable being used in a given css `var(...)` string
 * @param cssVariableString
 */
export const cssVariable = (cssVariableString: string) =>
  cssVariableString.replace(/var\(([^,)]+.*)\)$/, '$1');

/**
 * get the resolved value of a css variable call
 * @param value - the css variable call
 * @param styles - the styles to use to resolve the variable (default to document.documentElement computed styles)
 */
export const getVariableValue = (
  value: string | null,
  styles: CSSStyleDeclaration = getComputedStyle(document.documentElement)
) => {
  if (!value) {
    return;
  }

  const strValue = value?.toString().trim();
  if (strValue.startsWith('var(')) {
    const varName = cssVariable(strValue);
    return styles.getPropertyValue(varName);
  }
};

/**
 * Inlines the value of a css variable for the given element attribute
 * @param element - the element to inline the attribute for
 * @param attribute - the attribute to inline css variables values for
 */
export const inlineAttributesVariables = (
  element: Element,
  attribute: string
) => {
  const withAttribute = element.querySelectorAll(`[${attribute}]`);
  withAttribute.forEach((element) => {
    const styles = getComputedStyle(element);
    const value = element.getAttribute(attribute);
    const varValue = getVariableValue(value, styles);
    if (varValue) {
      element.setAttribute(attribute, varValue);
    }
  });
};

/**
 * Inlines the css styles for the given element and its descendants
 * @param root
 */
export const inlineCssStyles = (root: Element) => {
  const withClassNameOrId = root.querySelectorAll('[class], [id]');
  withClassNameOrId.forEach((element) => {
    const styles = getComputedStyle(element);
    const rules: Record<string, string> = {};
    for (let i = 0; i < styles.length; i++) {
      const property = styles[i];
      if (!property) {
        continue;
      }
      const value = styles.getPropertyValue(property);
      if (value) {
        const varValue = getVariableValue(value, styles);
        rules[property] = varValue ?? value;
        // Because within the image context, we don't have access to custom
        // fonts, we need to workaround the fact that the system font may be
        // wider than expected, so we use a reduced letter-spacing.
        if (property === '-webkit-line-clamp' && value !== 'none') {
          rules['letter-spacing'] = '-0.04em';
        }
      }
    }
    const style = element.getAttribute('style');
    const addedStyle = Object.entries(rules)
      .map(([property, value]) => `${property}: ${value}`)
      .join(';');
    element.setAttribute('style', `${style ? `${style};` : ''}${addedStyle};`);
    element.setAttribute('class', '');
  });
};

export const responsive = {
  /**
   * Returns a min-width @media rule that applies _starting at_ the given breakpoint
   * @param breakpoint
   * @param style
   */
  min: (breakpoint: Breakpoint, style: StyleRule) => {
    return {
      [`screen and (min-width: ${breakpoints[breakpoint]}px)`]: style,
    };
  },
  /**
   * Returns a max-width @media rule that applies _below_ the given breakpoint
   * @param breakpoint
   * @param style
   */
  max: (breakpoint: Breakpoint, style: StyleRule) => {
    return {
      [`screen and (max-width: ${breakpoints[breakpoint] - 1}px)`]: style,
    };
  },
};
