import * as React from 'react';
import { UseFormRegisterReturn, useFormContext } from 'react-hook-form';

import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { Typography } from '../Typography';

export type NumberFieldProps = UseFormRegisterReturn & {
  descriptionLabel: (value: number) => React.ReactNode;
  placeholderLabel: React.ReactNode;
  valueLabel: (value: number) => React.ReactNode;
};

export const NumberField = React.forwardRef<HTMLDivElement, NumberFieldProps>(
  ({ descriptionLabel, valueLabel, placeholderLabel, ...props }, ref) => {
    const form = useFormContext();

    const value: number | undefined | null = form.watch(props.name);
    const hasValue = typeof value === 'number';
    const valueNumber = value ?? 0;

    return (
      <Box
        ref={ref}
        alignItems="center"
        justifyContent="space-between"
        paddingVertical="s3"
      >
        <Box alignItems="center" gap="s2">
          <Typography
            color={hasValue ? 'primary' : 'tertiary'}
            fontVariant="tabular-nums"
            variant="bodyLargeEmphasis"
          >
            {hasValue ? valueLabel(value) : placeholderLabel}
          </Typography>
          <Typography variant="bodyMedium" color="tertiary">
            {hasValue ? descriptionLabel(value) : null}
          </Typography>
        </Box>

        <Box gap="s3" alignItems="center">
          <IconButton
            icon="minus"
            size="medium"
            type="button"
            variant="tertiary"
            disabled={valueNumber <= 0}
            aria-disabled={valueNumber <= 0}
            onClick={() => {
              form.setValue(
                props.name,
                valueNumber === 1 ? null : valueNumber - 1
              );
            }}
          />
          <IconButton
            icon="plus"
            size="medium"
            type="button"
            variant="tertiary"
            onClick={() => {
              form.setValue(props.name, valueNumber + 1);
            }}
          />
        </Box>
      </Box>
    );
  }
);

NumberField.displayName = 'NumberField';
