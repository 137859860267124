import * as Dialog from '@radix-ui/react-dialog';
import * as React from 'react';

import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { modalStyles } from '../Modal/Modal.css';
import { Typography } from '../Typography';

import { sideModalStyles } from './SideModal.css';

export type SideModalContentProps = {
  children: React.ReactNode;
  childContainerRef?: React.Ref<HTMLDivElement>;
  closeRef?: React.Ref<HTMLButtonElement>;
  description?: React.ReactNode;
  headerChildren?: React.ReactNode;
  headerPrefixIcon?: React.ReactNode;
  headerTabs?: React.ReactNode;
  overlayProps?: React.ComponentProps<typeof Dialog.Overlay>;
  preventContentScroll?: boolean;
  title?: React.ReactNode;
};

const SideModalContent = React.forwardRef<
  HTMLDivElement,
  SideModalContentProps
>(
  (
    {
      children,
      childContainerRef,
      title,
      description,
      headerPrefixIcon,
      headerChildren,
      preventContentScroll,
      overlayProps,
      headerTabs,
      closeRef,
    },
    ref
  ) => {
    const descriptionContent = description ? (
      <Dialog.Description asChild>
        <Typography variant="labelTag" color="tertiary">
          {description}
        </Typography>
      </Dialog.Description>
    ) : null;

    const titleContent = title ? (
      <Dialog.Title asChild>
        {typeof title === 'string' ? (
          <Typography
            as="h2"
            variant="headingMedium"
            color="primary"
            lineClamp={1}
            showTooltipWhenOverflowing
          >
            {title}
          </Typography>
        ) : (
          <Typography as="h2" variant="headingMedium" color="primary">
            {title}
          </Typography>
        )}
      </Dialog.Title>
    ) : null;

    return (
      <Dialog.Portal>
        <Dialog.Overlay className={modalStyles.overlay} {...overlayProps}>
          <Dialog.Content ref={ref} className={sideModalStyles.content}>
            <Box flexDirection="column">
              <Box
                className={sideModalStyles.header({ withTabs: !!headerTabs })}
                alignItems="center"
                flex={1}
              >
                <Box gap="s5" flex={1} alignItems="center">
                  {headerPrefixIcon ?? null}
                  <Box flexDirection="column" gap="s2">
                    {descriptionContent}
                    {titleContent}
                  </Box>
                  {headerChildren}
                </Box>
                <Dialog.Close asChild ref={closeRef}>
                  <IconButton icon="close" variant="secondary" size="small" />
                </Dialog.Close>
              </Box>
              {headerTabs}
            </Box>
            <Box
              flexDirection="column"
              overflowY={preventContentScroll ? 'hidden' : 'auto'}
              paddingTop="s6"
              flexGrow={1}
              ref={childContainerRef}
            >
              {children}
            </Box>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    );
  }
);

SideModalContent.displayName = 'SideModalContent';

export const SideModal = {
  Root: Dialog.Root,
  Trigger: Dialog.Trigger,
  Content: SideModalContent,
};
