import * as React from 'react';
import { SortableType, Sorter, SortOrder } from 'utils';

import { createTransition } from '../../utils/createTransition';
import { Box } from '../Box';
import { Icon } from '../Icon'; // Cell values are used for sorting, so they have to match the types we have

// Cell values are used for sorting, so they have to match the types we have
// sorter functions for
type CellValue = SortableType;

export type RenderCellProps<Datum> = {
  datum: Datum;
  getRowChildren: TableProps<unknown, Datum, unknown>['getRowChildren'];
  isOpen?: boolean;
  isRoot?: boolean;
};

export type Column<Value, Datum, Id> = {
  // The component to render the column cell
  Render: (props: RenderCellProps<Datum>) => React.ReactNode | null;
  // The column id, **must be typed `as const` to get proper type inference**
  id: Id;
  // The value for the column cell, as used in sorting
  value: (datum: Datum) => Value;
  // The component to render for the column's footer if any, receives the whole data array
  Footer?: (props: { data: Datum[] }) => React.ReactNode | null;
  // The component to render for the column's header if any
  Header?: (
    props: ColumnHeaderProps<Value, Datum, Id>
  ) => React.ReactNode | null;
  // The default SortOrder for the column, will be used the first time a user sorts on the column
  defaultSortOrder?: SortOrder;
  // Returns the props for the cell parent td
  getCellProps?: (props: {
    column: Column<Value, Datum, Id>;
    datum: Datum;
  }) => React.ComponentProps<'td'>;
  // Returns the props for the footer cell parent td
  getFooterCellProps?: (props: {
    column: Column<Value, Datum, Id>;
    data: Datum[];
  }) => React.ComponentProps<'td'>;
  // Returns the props for the header cell parent th
  getHeaderCellProps?: (
    props: ColumnHeaderProps<Value, Datum, Id>
  ) => React.ComponentProps<'th'>;
  // Will skip rendering this column if true
  skip?: boolean | ((data: Datum[]) => boolean);
  // The Sorter for the column, if the column is sortable
  sortable?: Value extends string | number | Date ? Sorter<Value> : never;
};

export type ColumnHeaderProps<Value, Datum, Id> = {
  column: Column<Value, Datum, Id>;
  setSortColumn: React.Dispatch<
    React.SetStateAction<Column<Value, Datum, Id> | undefined>
  >;
  setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>;
  sortColumn: Column<Value, Datum, Id> | undefined;
  sortOrder: SortOrder;
};

export type TableProps<Value, Datum, Id> = {
  columns: Column<Value, Datum, Id>[];
  data: Datum[];
  /**
   * A custom header row to render between the table header and the table body
   * Must be inside a `<tbody>` element
   */
  customHeader?: React.ReactNode;
  defaultSortId?: Id;
  defaultSortOrder?: SortOrder;
  getCellProps?: (props: {
    column: Column<Value, Datum, Id>;
    datum: Datum;
  }) => React.ComponentProps<'td'>;
  getDatumKey?: (props: { datum: Datum }) => string;
  getFooterCellProps?: (props: {
    column: Column<Value, Datum, Id>;
    data: Datum[];
  }) => React.ComponentProps<'td'>;
  getHeaderCellProps?: (
    props: ColumnHeaderProps<Value, Datum, Id>
  ) => React.ComponentProps<'th'>;
  getRowChildren?: (props: { datum: Datum }) => Datum[] | undefined;
  getTbodyTrProps?: (props: {
    datum: Datum;
    index: number;
  }) => React.ComponentProps<'tr'>;
  getTfootTrProps?: () => React.ComponentProps<'tr'>;
  getTheadTrProps?: () => React.ComponentProps<'tr'>;
  hideFooter?: boolean;
  openGroups?: Map<string, boolean>;
  tbodyProps?: React.ComponentProps<'tbody'>;
  tfootProps?: React.ComponentProps<'tfoot'>;
  theadProps?: React.ComponentProps<'thead'>;
} & React.ComponentProps<'table'>;

export const Table = <Datum, Id>({
  columns,
  data,
  defaultSortId,
  defaultSortOrder,
  hideFooter,
  theadProps,
  tbodyProps,
  tfootProps,
  openGroups,
  getRowChildren,
  getHeaderCellProps,
  getCellProps,
  getFooterCellProps,
  getTheadTrProps,
  getTbodyTrProps,
  getTfootTrProps,
  getDatumKey,
  customHeader,
  ...tableProps
}: TableProps<CellValue, Datum, Id>) => {
  // Make sure we have a correct default sort id before we proceed
  const validDefaultSortId =
    !defaultSortId || columns.some((column) => column.id === defaultSortId);
  if (!validDefaultSortId) {
    // Do not forget to either use an enum, or type the column.id `as const`
    // for type inference to work and get proper typescript assistance
    throw new Error(
      `Invalid column id given as Table defaultSortId: "${defaultSortId}"`
    );
  }

  // Get the default sort column
  const defaultSortColumn = defaultSortId
    ? columns.find((column) => column.id === defaultSortId)
    : undefined;

  // The current sort column state
  const [sortColumn, setSortColumn] = React.useState<
    Column<CellValue, Datum, Id> | undefined
  >(defaultSortColumn);

  // The current sort order state
  const [sortOrder, setSortOrder] = React.useState<SortOrder>(
    getDefaultSortOrder(defaultSortColumn, defaultSortOrder)
  );

  const sortedData = sortData(data, sortColumn, sortOrder);
  const columnsToRender = columns.filter(
    (column) =>
      !column.skip || (typeof column.skip === 'function' && !column.skip(data))
  );
  const hasFooter = columnsToRender.some((column) => !!column.Footer);
  const hasHeader = columnsToRender.some((column) => !!column.Header);

  const header = hasHeader ? (
    <thead {...theadProps}>
      <tr {...getTheadTrProps?.()}>
        {columnsToRender.map((column, i) => {
          const isCurrentSortColumn = column.id === sortColumn?.id;
          const headerProps: ColumnHeaderProps<CellValue, Datum, Id> = {
            column,
            sortColumn,
            sortOrder,
            setSortColumn,
            setSortOrder,
          };

          const sortIndicator = (
            <span
              data-sortindicator=""
              style={column.sortable ? {} : { opacity: 0 }}
              aria-hidden={!!column.sortable}
            >
              <Icon
                name={
                  sortOrder === SortOrder.ascending ? 'caretUp' : 'caretDown'
                }
                size="small"
                style={{
                  transition: createTransition(
                    ['transform', 'opacity'],
                    '0.1s',
                    'ease-in-out'
                  ),
                  opacity: isCurrentSortColumn ? 1 : 0.75,
                  transform: isCurrentSortColumn ? '' : 'scale(0)',
                }}
              />
            </span>
          );

          return (
            <th
              key={i}
              data-sortable={column.sortable ? '' : undefined}
              onMouseDown={(event) => event.preventDefault()}
              {...getHeaderCellProps?.(headerProps)}
              {...column.getHeaderCellProps?.(headerProps)}
            >
              <Box
                display="inline-flex"
                alignItems="center"
                style={column.sortable ? { cursor: 'pointer' } : undefined}
                onClick={() => {
                  if (column.sortable) {
                    setSortColumn(column);
                    setSortOrder(
                      sortColumn && column.id === sortColumn.id
                        ? -1 * (sortOrder ?? 1)
                        : getDefaultSortOrder(column, defaultSortOrder)
                    );
                  }
                }}
              >
                <span>
                  {column.Header ? <column.Header {...headerProps} /> : null}
                </span>
                {sortIndicator}
              </Box>
            </th>
          );
        })}
      </tr>
    </thead>
  ) : null;

  const renderRowChildren = (data: Datum[]) => {
    return data.map((datum, i) => {
      return (
        <tr
          key={getDatumKey?.({ datum }) ?? i}
          {...getTbodyTrProps?.({ datum, index: i })}
        >
          {columnsToRender.map((column, j) => (
            <td
              key={j}
              {...getCellProps?.({ datum, column })}
              {...column.getCellProps?.({ datum, column })}
            >
              <column.Render datum={datum} getRowChildren={getRowChildren} />
            </td>
          ))}
        </tr>
      );
    });
  };

  const body = sortedData.map((datum, i) => {
    const children = getRowChildren?.({ datum });
    const sortedChildren =
      children && sortData(children, sortColumn, sortOrder);
    const isParentGroup = !!sortedChildren?.length;
    const Cell = isParentGroup ? 'th' : 'td';

    return (
      <tbody key={i} {...tbodyProps}>
        <tr {...getTbodyTrProps?.({ datum, index: i })}>
          {columnsToRender.map((column, j) => (
            <Cell
              key={j}
              {...getCellProps?.({ datum, column })}
              {...column.getCellProps?.({ datum, column })}
            >
              <column.Render
                datum={datum}
                getRowChildren={getRowChildren}
                isOpen={
                  getDatumKey
                    ? !!openGroups?.get(getDatumKey({ datum }))
                    : false
                }
                isRoot
              />
            </Cell>
          ))}
        </tr>
        {isParentGroup && getDatumKey && openGroups?.get(getDatumKey({ datum }))
          ? renderRowChildren(sortedChildren)
          : null}
      </tbody>
    );
  });

  const footer =
    hasFooter && !hideFooter ? (
      <tfoot {...tfootProps}>
        <tr {...getTfootTrProps?.()}>
          {columnsToRender.map((column, i) => (
            <td
              key={i}
              {...getFooterCellProps?.({ data, column })}
              {...column.getFooterCellProps?.({ data, column })}
            >
              {column.Footer && <column.Footer data={data} />}
            </td>
          ))}
        </tr>
      </tfoot>
    ) : null;

  return (
    <table {...tableProps}>
      {header}
      {customHeader}
      {body}
      {footer}
    </table>
  );
};

const getDefaultSortOrder = <Value, Datum, Id>(
  column?: Column<Value, Datum, Id>,
  fallback = SortOrder.ascending
) => {
  return column?.defaultSortOrder ?? fallback;
};

const sortData = <Value, Datum, Id>(
  data: Datum[],
  sortColumn?: Column<Value, Datum, Id>,
  sortOrder = SortOrder.ascending
) => {
  if (sortOrder && data.length > 1 && sortColumn) {
    return [...data].sort(
      (a, b) =>
        sortOrder *
        (sortColumn.sortable
          ? sortColumn.sortable(sortColumn.value(a), sortColumn.value(b))
          : 0)
    );
  } else {
    return data;
  }
};
