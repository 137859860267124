import { Box, BoxProps } from '../Box';
import { Icon, IconName } from '../Icon';
import { Link, LinkProps } from '../Link';
import { Typography } from '../Typography';

import { alertStyles, AlertVariants } from './Alert.css';

export type AlertBaseProps = {
  title: string;
  body?: React.ReactNode;
  boxProps?: BoxProps;
  customIcon?: IconName;
  dismissible?: boolean;
  link?: LinkProps;
  onClose?: () => void;
};

export type AlertProps = AlertVariants & AlertBaseProps;

const MAP_VARIANT_WITH_ICON: Record<
  NonNullable<AlertProps['variant']>,
  IconName
> = {
  premium: 'finary-mark',
  info: 'infoCircle',
  warning: 'infoCircle',
  error: 'exclamationTriangle',
  success: 'check',
};

export const Alert = ({
  body,
  boxProps,
  title,
  variant = 'info',
  link,
  customIcon,
  dismissible,
  onClose,
}: AlertProps) => {
  return (
    <Box
      gap="s4"
      padding="s6"
      borderRadius="medium"
      flexDirection="column"
      className={alertStyles.root({ variant })}
      {...boxProps}
    >
      <Box flexDirection="column" gap="s2">
        <Box alignItems="center" gap="s5" as="header">
          <Box
            padding="s2"
            borderRadius="full"
            className={alertStyles.iconBackground({ variant })}
          >
            <Icon
              name={customIcon ?? MAP_VARIANT_WITH_ICON[variant]}
              size="small"
            />
          </Box>
          <Typography
            variant="bodyMediumEmphasis"
            className={alertStyles.title({ variant })}
            as="h4"
          >
            {title}
          </Typography>
          {dismissible ? (
            <button onClick={onClose}>
              <Icon
                name="close"
                size="large"
                className={alertStyles.closeIcon({ variant })}
              />
            </button>
          ) : null}
        </Box>

        {body ? (
          <Box flexDirection="column" paddingLeft="s8">
            <Typography
              variant="bodyMedium"
              color="primary"
              whiteSpace="pre-wrap"
            >
              {body}
            </Typography>
          </Box>
        ) : null}
      </Box>
      {link ? (
        <Box paddingLeft="s8">
          <Link
            variant={variant === 'info' ? 'primary' : 'secondary'}
            size="medium"
            {...link}
          />
        </Box>
      ) : null}
    </Box>
  );
};
