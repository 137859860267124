import { assignInlineVars } from '@vanilla-extract/dynamic';
import classNames from 'classnames';

import { palette } from '../../styles/theme.css';
import { Box, BoxProps } from '../Box';

import {
  progressBarStyles,
  progressColorVar,
  ProgressBarVariants,
} from './ProgressBar.css';

export type ProgressBarProps = {
  percent: number;
  progressColor?: keyof typeof palette;
} & BoxProps &
  ProgressBarVariants;

export const ProgressBar = ({
  percent,
  className,
  progressColor = 'brand500',
  size,
  ...props
}: ProgressBarProps) => {
  return (
    <Box
      background="tertiary"
      borderRadius="large"
      overflow="hidden"
      className={classNames(progressBarStyles.container({ size }), className)}
      {...props}
    >
      <Box
        className={progressBarStyles.progress}
        style={{
          width: `${percent}%`,
          ...assignInlineVars({
            [progressColorVar]: palette[progressColor],
          }),
        }}
      />
    </Box>
  );
};
