import * as React from 'react';

import { theme } from '../../styles/theme.css';
import { Box, BoxProps } from '../Box';
import { FinaryPlus } from '../FinaryPlus';
import { Icon } from '../Icon';
import { Link } from '../Link';
import { Typography } from '../Typography';

import {
  finaryPlusCardCTAStyles,
  FinaryPlusCardCTAVariants,
} from './FinaryPlusCardCTA.css';

export type FinaryPlusCardCTAProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  link?: string;
  size?: Exclude<FinaryPlusCardCTAVariants, undefined>['size'];
} & Omit<BoxProps<'div' | 'button'>, 'title'> &
  (
    | {
        variant: 'gradient';
        withLogo?: never;
      }
    | { variant?: 'default'; withLogo?: boolean }
  );

export const FinaryPlusCardCTA = ({
  title,
  description,
  variant,
  link,
  size,
  withLogo = false,
  ...props
}: FinaryPlusCardCTAProps) => {
  return (
    <Box
      gap="s3"
      justifyContent="space-between"
      alignItems="center"
      className={finaryPlusCardCTAStyles.card({ variant, size })}
      {...props}
    >
      {withLogo ? (
        <svg
          className={finaryPlusCardCTAStyles.logo}
          width="221"
          height="149"
          viewBox="0 0 221 149"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask id="path-1-inside-1_3308_48129" fill="red">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M65.8437 0C29.4792 0 0 29.4792 0 65.8437H155.156C191.521 65.8437 221 36.3645 221 0H65.8437ZM65.8437 82.3008C29.4793 82.3008 0 111.78 0 148.145H76.2277C112.592 148.145 142.071 118.665 142.071 82.3008H65.8437Z"
            />
          </mask>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.8437 0C29.4792 0 0 29.4792 0 65.8437H155.156C191.521 65.8437 221 36.3645 221 0H65.8437ZM65.8437 82.3008C29.4793 82.3008 0 111.78 0 148.145H76.2277C112.592 148.145 142.071 118.665 142.071 82.3008H65.8437Z"
            fill="url(#paint0_linear_3308_48129)"
          />
          <path
            d="M0 65.8437H-1V66.8437H0V65.8437ZM221 0H222V-1H221V0ZM0 148.145H-1V149.145H0V148.145ZM142.071 82.3008H143.071V81.3008H142.071V82.3008ZM1 65.8437C1 30.0315 30.0315 1 65.8437 1V-1C28.927 -1 -1 28.9269 -1 65.8437H1ZM155.156 64.8437H0V66.8437H155.156V64.8437ZM220 0C220 35.8122 190.969 64.8437 155.156 64.8437V66.8437C192.073 66.8437 222 36.9168 222 0H220ZM65.8437 1H221V-1H65.8437V1ZM1 148.145C1 112.332 30.0315 83.3008 65.8437 83.3008V81.3008C28.927 81.3008 -1 111.228 -1 148.145H1ZM76.2277 147.145H0V149.145H76.2277V147.145ZM141.071 82.3008C141.071 118.113 112.04 147.145 76.2277 147.145V149.145C113.144 149.145 143.071 119.218 143.071 82.3008H141.071ZM65.8437 83.3008H142.071V81.3008H65.8437V83.3008Z"
            fill={theme.color.border.secondary}
            mask="url(#path-1-inside-1_3308_48129)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3308_48129"
              x1="110.5"
              y1="0"
              x2="110.5"
              y2="148.145"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={theme.color.surface.secondary} />
              <stop
                offset="0.442747"
                stopColor={theme.color.surface.secondary}
                stopOpacity="0.4"
              />
              <stop
                offset="0.552126"
                stopColor={theme.color.surface.secondary}
              />
              <stop
                offset="1"
                stopColor={theme.color.surface.secondary}
                stopOpacity="0.4"
              />
            </linearGradient>
          </defs>
        </svg>
      ) : undefined}
      <Box gap="s2" flexDirection="column" position="relative">
        <FinaryPlus />
        <Box flex={1} flexDirection="column" gap="s2">
          <Typography
            variant={size === 'large' ? 'headingMedium' : 'bodyMediumEmphasis'}
            color="currentColor"
            className={finaryPlusCardCTAStyles.title({ variant })}
          >
            {title}
          </Typography>
          <Typography variant="bodyMedium" color="secondary">
            {description}
          </Typography>
        </Box>
      </Box>
      <Box position="relative">
        {link ? (
          <Link suffixIcon="chevronRight">{link}</Link>
        ) : (
          <Icon
            name="chevronRight"
            className={finaryPlusCardCTAStyles.icon({ variant })}
            size="small"
          />
        )}
      </Box>
    </Box>
  );
};
