import * as React from 'react';

import { Box, BoxProps } from '../Box';
import { IconButton, IconButtonProps } from '../IconButton';
import { Typography } from '../Typography';

import { CtaButtonVariants, ctaButtonStyle } from './CtaButton.css';

export type CtaButtonSize = 'small' | 'large';

export type CtaButtonProps = {
  icon: IconButtonProps['icon'];
  title: string;
  disabled?: boolean;
  size?: CtaButtonSize;
} & CtaButtonVariants &
  BoxProps<'button'> &
  (
    | {
        direction?: 'row' | 'row-reverse';
        suffix?: React.ReactNode;
      }
    | {
        direction?: 'column' | 'column-reverse';
        suffix?: never;
      }
  );

const ICON_VARIANT_MAPPING: Record<
  string,
  IconButtonProps<'button'>['variant']
> = {
  secondary: 'quaternary',
  tertiary: 'tertiary',
  primary: 'primary',
};

const mapIconVariant = (
  variant: CtaButtonProps['variant'],
  direction: CtaButtonProps['direction']
) => {
  if (variant === 'tertiary' && direction === 'column') {
    return 'quaternary';
  }

  return ICON_VARIANT_MAPPING[variant ?? 'primary'];
};

export const CtaButton = ({
  direction = 'row',
  icon,
  title,
  variant = 'primary',
  disabled,
  suffix,
  size = 'large',
  type,
  ...props
}: CtaButtonProps) => {
  return (
    <Box
      flexDirection={direction}
      paddingVertical="s5"
      paddingHorizontal={direction === 'row' ? 's5' : 's6'}
      justifyContent="space-between"
      alignItems="center"
      as="button"
      className={ctaButtonStyle.container({ variant })}
      disabled={disabled}
      type={type}
      {...props}
    >
      <Box alignItems="center" gap="s5" flexDirection={direction}>
        <IconButton
          size={size === 'small' ? 'small' : 'medium'}
          icon={icon}
          type={type}
          variant={mapIconVariant(variant, direction)}
          style={disabled ? { pointerEvents: 'none' } : undefined}
        />
        <Typography
          variant="labelSmall"
          color={variant === 'primary' ? 'accentPrimary' : 'primary'}
        >
          {title}
        </Typography>
      </Box>
      {suffix}
    </Box>
  );
};
