import { copy } from 'utils';

import { useFeedback } from './useFeedback';

export const useCopyValue = (value: string) => {
  const [feedback, setFeedback, lastFeedback] = useFeedback<
    'success' | 'error'
  >();

  const handleCopyValue: React.MouseEventHandler = (event) => {
    const isSuccess = copy(value, event.currentTarget);
    setFeedback(isSuccess ? 'success' : 'error');
    event.preventDefault();
    event.stopPropagation();
  };

  return {
    feedback,
    lastFeedback,
    handleCopyValue,
  };
};
