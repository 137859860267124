import * as React from 'react';

import { Box, BoxProps } from '../Box';
import { Typography } from '../Typography';

import {
  SelectableTile,
  SelectableTileProps,
  SelectableTileWithFreeValueProps,
} from './SelectableTile';

export type SelectableTilesProps = {
  tiles: (
    | Omit<SelectableTileProps, 'multiple' | 'ref'>
    | Omit<SelectableTileWithFreeValueProps, 'multiple' | 'ref'>
  )[];
  direction?: 'column' | 'row';
  label?: string;
  multiple?: boolean;
  selectableBoxContainerProps?: BoxProps;
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'placeholder'>;

export const SelectableTiles = React.forwardRef<
  HTMLInputElement,
  SelectableTilesProps
>(
  (
    {
      tiles,
      label,
      multiple,
      direction = 'column',
      selectableBoxContainerProps,
      ...rest
    },
    ref
  ) => {
    return (
      <Box flexDirection="column" gap="s4">
        {label ? (
          <Typography as="label" variant="bodyLargeEmphasis" color="tertiary">
            {label}
          </Typography>
        ) : null}
        <Box
          flexDirection={direction === 'column' ? 'column' : 'row'}
          gap="s3"
          style={{ width: '100%' }}
          {...selectableBoxContainerProps}
        >
          {tiles.map((tile, index) => (
            <SelectableTile
              ref={ref}
              key={index}
              multiple={multiple}
              centered={direction === 'row'}
              {...tile}
              {...rest}
            />
          ))}
        </Box>
      </Box>
    );
  }
);

SelectableTiles.displayName = 'SelectableTiles';
