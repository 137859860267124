import * as React from 'react';

import { Box } from '../Box';
import { Icon, IconName } from '../Icon';
import { Link } from '../Link';
import { Typography } from '../Typography';

import { bannerStyles, BannerVariants } from './Banner.css';

export type BannerVariant = NonNullable<NonNullable<BannerVariants>['variant']>;

const BANNER_ICON: Record<BannerVariant, IconName> = {
  danger: 'exclamationTriangle',
  info: 'infoCircle',
  success: 'check',
  warning: 'exclamationCircle',
  plus: 'finary-mark',
};

export type BannerBaseProps = BannerVariants & {
  title: React.ReactNode;
  bannerIcon?: IconName;
  bannerIconVariant?: NonNullable<BannerVariants>['variant'];
  body?: React.ReactNode;
  dismissible?: boolean;
  onClick?: () => void;
  onClose?: () => void;
};

export type BannerWithoutLinkProps = BannerBaseProps & {
  link?: never;
};

export type BannerWithLinkProps = BannerBaseProps & {
  link: React.ReactNode;
};

export type BannerProps = BannerWithoutLinkProps | BannerWithLinkProps;

export const Banner = ({
  title,
  body,
  link,
  bannerIcon,
  bannerIconVariant,
  dismissible,
  variant = 'info',
  align = 'start',
  layout = 'multiLine',
  onClose,
  onClick,
}: BannerProps) => {
  const [open, setOpen] = React.useState(true);

  const titleComponent = (
    <Typography
      variant="bodyMediumEmphasis"
      className={bannerStyles.title({ variant })}
      as="h4"
    >
      {title}
    </Typography>
  );

  const titleWithOptionalGradient =
    variant === 'plus' ? (
      <Typography variant="gradient">{titleComponent}</Typography>
    ) : (
      titleComponent
    );

  return open ? (
    <Box
      as="header"
      alignItems="flex-start"
      gap="s6"
      className={bannerStyles.banner({ variant, align, layout })}
      style={{ cursor: onClick ? 'pointer' : undefined }}
      onClick={onClick}
    >
      <Box className={bannerStyles.contentWrapper} flex={1} gap="s5">
        <Box alignItems="flex-start" gap="s4">
          <Box
            padding="s2"
            borderRadius="full"
            className={bannerStyles.iconBackground({
              variant: bannerIconVariant ?? variant,
            })}
          >
            <Icon
              name={bannerIcon ?? BANNER_ICON[variant]}
              size="small"
              className={bannerStyles.icon({
                variant: bannerIconVariant ?? variant,
              })}
            />
          </Box>
        </Box>
        <Box className={bannerStyles.content}>
          <Box className={bannerStyles.innerContent}>
            {titleWithOptionalGradient}
            {body ? (
              <Box>
                <Typography variant="bodyMedium">{body}</Typography>
              </Box>
            ) : null}
          </Box>
          {link ? (
            <Link
              size="medium"
              variant={variant === 'info' ? 'primary' : 'secondary'}
            >
              {link}
            </Link>
          ) : null}
        </Box>
      </Box>
      {dismissible ? (
        <Box alignItems="center" style={{ height: '100%' }}>
          <Box<'button'>
            as="button"
            className={bannerStyles.closeIcon({ variant })}
            onClick={(event) => {
              event.stopPropagation();
              onClose?.();
              setOpen(false);
            }}
          >
            <Icon name="close" size="large" />
          </Box>
        </Box>
      ) : null}
    </Box>
  ) : null;
};
