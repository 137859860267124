export const breakpoints = {
  tablet: 940,
  desktop: 1192,
  wide: 1400,
} as const;

export type Breakpoint = keyof typeof breakpoints;

export const breakpointNames = Object.keys(
  breakpoints
) as readonly Breakpoint[];

export const mediaQueries = {
  mobile: {},
  tablet: {
    '@media': `screen and (min-width: ${breakpoints.tablet}px)`,
  },
  desktop: {
    '@media': `screen and (min-width: ${breakpoints.desktop}px)`,
  },
  wide: { '@media': `screen and (min-width: ${breakpoints.wide}px)` },
} as const;
