import classNames from 'classnames';
import * as React from 'react';

import { useCombinedRefs } from '../../hooks/useCombinedRefs';
import { Box } from '../Box';

import { uploadFileStyles } from './UploadFile.css';

export type UploadFileProps = Pick<React.ComponentProps<'input'>, 'accept'> & {
  error?: boolean;
  onAddFiles?: (files: File[]) => void;
};

export const UploadFile = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<UploadFileProps>
>(({ children, accept, error, onAddFiles }, ref) => {
  const id = React.useId();
  const containerRef = React.useRef<HTMLLabelElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const combinedRef = useCombinedRefs(ref, inputRef);

  // used to remove active border class when the user cancels the file upload
  React.useEffect(() => {
    const inputRefCurrent = inputRef.current;

    inputRefCurrent?.addEventListener('cancel', removeActiveBorderClass);

    return () => {
      inputRefCurrent?.removeEventListener('cancel', removeActiveBorderClass);
    };
  }, []);

  const addActiveBorderClass = () =>
    containerRef.current?.classList.add(uploadFileStyles.containerDragOver);

  const removeActiveBorderClass = () =>
    containerRef.current?.classList.remove(uploadFileStyles.containerDragOver);

  const allowLabelDrop: React.DragEventHandler<HTMLLabelElement> = (event) =>
    event.preventDefault();

  const handleLabelDrop: React.DragEventHandler<HTMLLabelElement> = (event) => {
    event.preventDefault();
    onAddFiles?.(Array.from(event.dataTransfer.files));
    removeActiveBorderClass();
  };

  return (
    <Box
      tabIndex={0}
      as="label"
      draggable
      htmlFor={id}
      ref={containerRef}
      className={classNames(uploadFileStyles.container, {
        [uploadFileStyles.containerError]: error,
      })}
      onDragEnter={addActiveBorderClass}
      onDragLeave={removeActiveBorderClass}
      onDragOver={allowLabelDrop}
      onDrop={handleLabelDrop}
      onKeyDown={(event: React.KeyboardEvent) => {
        if (event.key === ' ' || event.key === 'Enter') {
          event.preventDefault();
          inputRef.current?.click();
        }
      }}
      onClick={addActiveBorderClass}
    >
      {children}
      <input
        id={id}
        type="file"
        tabIndex={-1}
        accept={accept}
        ref={combinedRef}
        className={uploadFileStyles.input}
        onChange={(event) => {
          event.preventDefault();
          onAddFiles?.(Array.from(event.target.files ?? []));
        }}
      />
    </Box>
  );
});

UploadFile.displayName = 'UploadFile';
