import { Box } from '../Box';
import { Typography } from '../Typography';

export type InstructionItemProps = {
  index: number;
  label: React.ReactNode;
};

export const InstructionItem = ({ index, label }: InstructionItemProps) => {
  return (
    <Box alignItems="center" gap="s5">
      <Box
        padding="s4"
        borderRadius="medium"
        background="secondary"
        alignItems="center"
        justifyContent="center"
        style={{
          height: 48,
          width: 48,
        }}
      >
        <Typography variant="labelCaption" color="primary">
          {index + 1 < 10 ? '0' : ''}
          {index + 1}
        </Typography>
      </Box>
      <Typography variant="bodyMedium" color="secondary">
        {label}
      </Typography>
    </Box>
  );
};
