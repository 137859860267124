import { AssetLogo, AssetLogoProps } from '../AssetLogo';
import { Box, BoxProps } from '../Box';
import { Copyable } from '../Copyable';
import { Separator } from '../Separator';
import { Skeleton } from '../Skeleton';
import { Typography } from '../Typography';

export type AssetRecommandationCardProps = {
  isin: string;
  logoUrl: AssetLogoProps['src'];
  name: string;
  footer?: React.ReactNode;
  type?: React.ReactNode;
} & BoxProps<'div'>;

export const AssetRecommandationCard = ({
  logoUrl,
  type,
  name,
  isin,
  footer,
  ...props
}: AssetRecommandationCardProps) => {
  return (
    <Box
      background="primary"
      borderRadius="medium"
      flexDirection="column"
      paddingVertical="s6"
      {...props}
    >
      <Box flexDirection="column" paddingHorizontal="s6">
        <AssetLogo src={logoUrl} />
        <Box flexDirection="column" marginTop="s7" gap="s3">
          <Typography color="accentPrimary" variant="labelCaption">
            {type}
          </Typography>
          <Typography
            color="primary"
            variant="bodyMedium"
            lineClamp={1}
            showTooltipWhenOverflowing
          >
            {name}
          </Typography>
          <Copyable variant="bodyMedium" color="tertiary" value={isin} />
        </Box>
      </Box>
      <Separator variant="horizontal" marginVertical="s6" />
      {footer}
    </Box>
  );
};

export const AssetRecommandationCardLoader = ({
  footer,
  ...props
}: Pick<AssetRecommandationCardProps, 'footer'> & BoxProps<'div'>) => {
  return (
    <Box
      background="primary"
      borderRadius="medium"
      flexDirection="column"
      paddingVertical="s6"
      {...props}
    >
      <Box flexDirection="column" paddingHorizontal="s6">
        <Skeleton width={32} height={32} radius={32} />
        <Box flexDirection="column" marginTop="s7" gap="s3">
          <Skeleton height={12} width={30} radius={4} />
          <Skeleton height={20} width={120} radius={4} />
          <Skeleton height={20} width={80} radius={4} />
        </Box>
      </Box>
      <Separator variant="horizontal" marginVertical="s6" />
      {footer}
    </Box>
  );
};
