import { Select, SelectProps } from '../Select';

import {
  SelectFieldCustomTrigger,
  SelectFieldCustomTriggerProps,
} from './SelectFieldCustomTrigger';

export type SelectFieldProps<T extends string> = SelectProps<T> &
  SelectFieldCustomTriggerProps & {
    dropDownWidth?: number | string | 'parent';
    maxDropDownHeight?: number | string;
    optionalLabel?: string;
  };

export const SelectField = <T extends string>({
  label,
  children,
  dropDownWidth,
  style,
  maxDropDownHeight,
  optionalLabel,
  suffix,
  ...props
}: SelectFieldProps<T>) => {
  return (
    <Select
      {...props}
      style={{
        ...style,
        ...(dropDownWidth
          ? {
              width:
                dropDownWidth === 'parent'
                  ? 'var(--radix-dropdown-menu-trigger-width)'
                  : dropDownWidth,
              maxHeight: maxDropDownHeight,
              '--radix-popper-transform-origin':
                dropDownWidth === 'parent' ? '50% 0' : undefined,
            }
          : {}),
      }}
    >
      <SelectFieldCustomTrigger
        label={label}
        disabled={props.disabled}
        optionalLabel={optionalLabel}
        suffix={suffix}
      >
        {children}
      </SelectFieldCustomTrigger>
    </Select>
  );
};
