import classNames from 'classnames';
import { forwardRef, PropsWithChildren } from 'react';
import type { DistributiveOmit } from 'utils';

import { AssetLogo, AssetLogoProps } from '../AssetLogo';
import { Box } from '../Box';
import { Icon, IconName, IconProps } from '../Icon';
import { Typography } from '../Typography';

import { chipTagStyle } from './ChipTag.css';

export type ChipTagProps = {
  assetLogoProps?: DistributiveOmit<AssetLogoProps, 'size'>;
  disabled?: boolean;
  iconProps?: IconProps;
  onClick?: () => void;
  suffixIcon?: IconName;
};

export const ChipTag = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ChipTagProps>
>(
  (
    {
      iconProps,
      assetLogoProps,
      children,
      suffixIcon,
      disabled,
      onClick,
    }: PropsWithChildren<ChipTagProps>,
    ref
  ) => {
    const isCircle =
      ((iconProps && !assetLogoProps) || (assetLogoProps && !iconProps)) &&
      !children &&
      !suffixIcon;

    return (
      <Box
        ref={ref}
        as={onClick ? 'button' : 'div'}
        className={classNames(chipTagStyle.container, {
          [chipTagStyle.withAction]: !!onClick && !disabled,
          [chipTagStyle.circle]: isCircle,
        })}
        onClick={onClick}
      >
        {iconProps ? <Icon size="small" {...iconProps} /> : undefined}
        {assetLogoProps ? (
          <AssetLogo size="small" {...assetLogoProps} />
        ) : undefined}
        {children ? (
          <Typography
            variant="labelXSmall"
            gap="s3"
            display="flex"
            alignItems="center"
            color={disabled ? 'disabled' : 'primary'}
          >
            {children}
          </Typography>
        ) : undefined}
        {suffixIcon ? (
          <Icon
            size="small"
            name={suffixIcon}
            color={disabled ? 'disabled' : 'primary'}
          />
        ) : undefined}
      </Box>
    );
  }
);

ChipTag.displayName = 'ChipTag';
