import classNames from 'classnames';
import * as React from 'react';

import { Box } from '../Box';
import { Icon, IconName } from '../Icon';
import { Typography, TypographyVariants } from '../Typography';

import { chipInputStyles, chipStyles, ChipVariants } from './Chip.css';

export type ChipInputBaseProps = ChipVariants &
  Omit<React.ComponentPropsWithoutRef<'input'>, 'type' | 'size'> & {
    type: 'radio' | 'checkbox';
    className?: string;
    prefixIcon?: IconName;
    suffixIcon?: IconName;
  };

export type ChipInputProps = React.PropsWithChildren<ChipInputBaseProps>;

export const ChipInput = React.forwardRef<HTMLInputElement, ChipInputProps>(
  (
    {
      type,
      active,
      size = 'medium',
      variant = 'primary',
      prefixIcon,
      suffixIcon,
      children,
      className,
      ...componentProps
    },
    ref?
  ) => {
    const MAP_SIZE_TO_TEXT_VARIANT: {
      [key in typeof size]: TypographyVariants;
    } = {
      xsmall: 'labelCaption',
      small: 'labelXSmall',
      medium: 'labelSmall',
    };

    const childrenWithOptionalGradient =
      variant === 'gradient' ? (
        <Typography variant="gradient">{children}</Typography>
      ) : (
        children
      );

    return (
      <label>
        <input
          ref={ref}
          type={type}
          className={chipInputStyles}
          {...componentProps}
        />
        <Box
          justifyContent="center"
          className={classNames(
            chipStyles({ active, size, variant }),
            className
          )}
        >
          <Box alignItems="center" justifyContent="center" gap="s3">
            {prefixIcon ? <Icon name={prefixIcon} size={size} /> : null}
            <Typography variant={MAP_SIZE_TO_TEXT_VARIANT[size]}>
              {childrenWithOptionalGradient}
            </Typography>
            {suffixIcon ? <Icon name={suffixIcon} size={size} /> : null}
          </Box>
        </Box>
      </label>
    );
  }
);

ChipInput.displayName = 'ChipInput';
