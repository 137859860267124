import { Box, BoxProps } from '../Box';

import {
  SideNavigationMenuItem,
  SideNavigationMenuItemData,
} from './SideNavigationMenuItem';

export type SideNavigationMenuProps<T extends string> = {
  activeItem: SideNavigationMenuItemData<T> | undefined;
  items: SideNavigationMenuItemData<T>[];
  onClickItem: (item: SideNavigationMenuItemData<T>) => void;
} & BoxProps;

export const SideNavigationMenu = <T extends string>({
  items,
  activeItem,
  onClickItem,
  ...props
}: SideNavigationMenuProps<T>) => {
  return (
    <Box as="aside" {...props}>
      <Box as="ul" flexDirection="column" gap="s2">
        {items.map((item) => (
          <Box as="li" key={item.key}>
            <Box
              as="button"
              type="button"
              onClick={() => onClickItem(item)}
              disabled={item.disabled}
              borderRadius="medium"
              style={{
                cursor: item.disabled ? 'not-allowed' : undefined,
              }}
            >
              <SideNavigationMenuItem<T>
                item={item}
                isActive={activeItem?.title === item.title}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
