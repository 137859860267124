import React from 'react';

import { theme } from '../../styles/theme.css';
import { AssetLogo, AssetLogoLoader } from '../AssetLogo';
import { Box, BoxProps } from '../Box';
import { Icon, IconName } from '../Icon';
import { Skeleton } from '../Skeleton';
import { Typography } from '../Typography';

import { listItemStyles, ListItemStylesVariant } from './ListItem.css';

export type ListItemProps<T extends 'button' | 'div' = 'div'> = Omit<
  BoxProps<T>,
  'title'
> & {
  title: React.ReactNode;
  customLogo?: React.ReactNode;
  description?: React.ReactNode;
  disabled?: boolean;
  hideValue?: boolean;
  icon?: IconName;
  isActive?: boolean;
  logo_url?: string | null;
  noAssetLogoFallback?: boolean;
  noLogo?: boolean;
  suffix?: React.ReactNode;
  withSyncIcon?: boolean;
} & ListItemStylesVariant;

export const ListItem = <T extends 'div' | 'button' = 'div'>({
  description,
  title,
  logo_url,
  icon,
  onClick,
  suffix,
  withSyncIcon,
  noLogo,
  customLogo,
  variant,
  hideValue,
  noAssetLogoFallback,
  isActive,
  disabled,
  ...props
}: ListItemProps<T>) => {
  const renderLogo = () => {
    if (noLogo) {
      return null;
    }
    if (icon) {
      return <AssetLogo size="large" icon={icon} />;
    }

    if (customLogo) {
      return customLogo;
    }

    return <AssetLogo src={logo_url} noFallback={noAssetLogoFallback} />;
  };

  return (
    <Box<'button' | 'div'>
      as={onClick && !disabled ? 'button' : 'div'}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      className={listItemStyles.root({ variant })}
      {...props}
    >
      {!noLogo || withSyncIcon || icon || customLogo ? (
        <Box position="relative">
          {renderLogo()}
          {withSyncIcon ? (
            <Box
              className={listItemStyles.linkIconContainer}
              background="accentPrimary"
              justifyContent="center"
              alignItems="center"
              borderRadius="full"
            >
              <Icon
                name="linkSimpleSolid"
                style={{ color: theme.color.background.primary }}
                size="xsmall"
              />
            </Box>
          ) : null}
        </Box>
      ) : null}
      <Box flex={1} flexDirection="column">
        <Typography
          variant="bodyLarge"
          color={isActive ? 'accentPrimary' : 'primary'}
          lineClamp={hideValue ? undefined : 1}
          showTooltipWhenOverflowing={!hideValue}
          hideValue={hideValue}
        >
          {title}
        </Typography>
        {description ? (
          <Typography
            variant="bodyMedium"
            color="tertiary"
            hideValue={hideValue}
          >
            {description}
          </Typography>
        ) : null}
      </Box>
      {isActive ? (
        <Icon name="check" size="large" color="accentPrimary" />
      ) : null}
      {suffix ?? null}
    </Box>
  );
};

export type ListItemLoaderProps = {
  withDescription?: boolean;
} & ListItemStylesVariant;

export const ListItemLoader = ({
  withDescription,
  variant,
}: ListItemLoaderProps) => {
  return (
    <Box className={listItemStyles.root({ variant })}>
      <AssetLogoLoader />
      <Box flexDirection="column" gap="s2">
        <Skeleton width={100} height={20} radius={4} />
        {withDescription ? (
          <Skeleton width={120} height={20} radius={4} />
        ) : null}
      </Box>
    </Box>
  );
};
