import * as React from 'react';

export const StepSideModalParamsContext = React.createContext<
  object | undefined
>(undefined);

export const StepSideModalParamsProvider = ({
  children,
  params,
}: {
  children: React.ReactNode;
  params?: object;
}) => {
  return (
    <StepSideModalParamsContext.Provider value={params}>
      {children}
    </StepSideModalParamsContext.Provider>
  );
};
