import { Box, BoxProps } from '../Box';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

import { sideNavigationMenuItemStyles } from './SideNavigationMenuItem.css';

export type SideNavigationMenuItemData<T extends string> = {
  title: string;
  disabled?: boolean;
  isInError?: boolean;
  key?: T;
  screen?: React.ReactNode;
};

export type SideNavigationMenuItemProps<T extends string> = {
  isActive: boolean;
  item: SideNavigationMenuItemData<T>;
};

export const SideNavigationMenuItem = <
  T extends string,
  C extends React.ElementType = 'div'
>({
  item,
  isActive,
  ...props
}: SideNavigationMenuItemProps<T> & BoxProps<C>) => {
  return (
    <Box
      backgroundColor={isActive ? 'overlay' : undefined}
      className={sideNavigationMenuItemStyles.root}
      {...(props as BoxProps<'div'>)}
    >
      <Typography
        variant="bodyMedium"
        color={
          item.disabled
            ? 'disabled'
            : item.isInError
            ? 'error'
            : isActive
            ? 'accentPrimary'
            : 'secondary'
        }
        lineClamp={1}
        wordBreak="break-all"
      >
        {item.title}
      </Typography>
      {item.isInError ? (
        <Box marginLeft="s4">
          <Icon name="exclamationCircle" color="error" />
        </Box>
      ) : null}
    </Box>
  );
};
