import classNames from 'classnames';
import * as React from 'react';

import { Box } from '../Box';
import { Icon, IconProps } from '../Icon';
import { Skeleton } from '../Skeleton';
import { Typography } from '../Typography';

import { selectableTileStyles } from './SelectableTile.css';

type BaseSelectableTileProps = Omit<React.ComponentProps<'input'>, 'title'> & {
  title: React.ReactNode;
  centered?: boolean;
  icon?: IconProps['name'];
  img?: string;
  imgAsIcon?: boolean;
  multiple?: boolean;
};

export type SelectableTileProps = BaseSelectableTileProps & {
  customValue?: false;
  defaultValues?: never;
  label?: React.ReactNode;
  placeholder?: never;
};

export type SelectableTileWithFreeValueProps = BaseSelectableTileProps & {
  customValue: true;
  defaultValues: string[];
  placeholder: string;
  label?: never;
};

export const SelectableTile = React.forwardRef<
  HTMLInputElement,
  SelectableTileProps | SelectableTileWithFreeValueProps
>(
  (
    {
      multiple,
      customValue,
      placeholder,
      defaultValues,
      checked,
      icon,
      img,
      imgAsIcon,
      label,
      title,
      name,
      value,
      centered,
      onChange,
      ...rest
    },
    forwardedRef
  ) => {
    const [currentFreeValue, setCurrentFreeValue] = React.useState<string>(
      defaultValues &&
        typeof value === 'string' &&
        !defaultValues.includes(value)
        ? value
        : ''
    );
    const containerRef = React.useRef<HTMLLabelElement>(null);

    return (
      <label
        style={{
          cursor: 'pointer',
          flex: centered ? 1 : undefined,
          position: 'relative',
        }}
        ref={containerRef}
      >
        <input
          ref={forwardedRef}
          className={selectableTileStyles.input}
          type={multiple ? 'checkbox' : 'radio'}
          value={currentFreeValue || value}
          name={name}
          checked={checked}
          onChange={(e) => {
            const input =
              e.currentTarget.parentElement?.querySelector('input[type=text]');
            if (input instanceof HTMLInputElement) {
              input.focus({ preventScroll: true });
            }
            onChange?.(e);
          }}
          {...rest}
        />
        <Box
          alignItems="center"
          borderRadius="medium"
          className={classNames(
            selectableTileStyles.tile,
            centered ? selectableTileStyles.centeredTile : undefined
          )}
          gap="s4"
        >
          {img ? (
            imgAsIcon ? (
              <Box className={selectableTileStyles.icon}>
                <img src={img} className={selectableTileStyles.imgAsIcon} />
              </Box>
            ) : (
              <img className={selectableTileStyles.img} src={img} />
            )
          ) : icon ? (
            <Box className={selectableTileStyles.icon}>
              <Icon name={icon} color="currentColor" />
            </Box>
          ) : null}
          <Box flexDirection="column">
            {label ? (
              <Typography
                className={selectableTileStyles.label}
                variant="bodyMedium"
                lineClamp={1}
                showTooltipWhenOverflowing
                useTooltipPortal
                marginBottom="s2"
              >
                {label}
              </Typography>
            ) : null}
            <Typography
              variant="labelMedium"
              className={customValue ? selectableTileStyles.title : undefined}
              lineClamp={1}
              showTooltipWhenOverflowing
              useTooltipPortal
            >
              {title}
            </Typography>
            {customValue ? (
              <input
                type="text"
                placeholder={placeholder}
                className={selectableTileStyles.customValueInput}
                name={name}
                {...rest}
                onChange={(e) => {
                  setCurrentFreeValue(e.target.value);
                  const element = e.target
                    .closest('title')
                    ?.querySelector('input');
                  if (element) {
                    element?.dispatchEvent(new Event('change'));
                  }
                }}
                value={currentFreeValue}
                style={{ flex: 1 }}
              />
            ) : null}
          </Box>
        </Box>
      </label>
    );
  }
);

SelectableTile.displayName = 'SelectableTile';

export const SelectableTileLoader = ({
  withLabel,
}: {
  withLabel?: boolean;
}) => {
  return (
    <Box
      gap="s4"
      alignItems="center"
      borderRadius="medium"
      className={selectableTileStyles.tile}
    >
      <Skeleton width={40} height={40} radius={20} />
      <Box flexDirection="column" gap="s2">
        {withLabel ? <Skeleton width={40} height={16} radius={4} /> : null}

        <Skeleton width={80} height={16} radius={4} />
      </Box>
    </Box>
  );
};
