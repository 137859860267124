import { Box, BoxProps } from '../Box';
import { NumberFormat, NumberFormatProps } from '../NumberFormat';
import { Typography, TypographyVariants } from '../Typography';

import { PNLStyles, PNLVariants } from './PnlPercent.css';

export type PNLPercentProps = BoxProps &
  Required<PNLVariants> & {
    value?: number | null;
  } & Pick<NumberFormatProps, 'digit'>;

export const PnlPercent = ({
  value,
  size,
  digit,
  ...props
}: PNLPercentProps) => {
  const MAP_SIZE_TO_TEXT_VARIANT: {
    [key in typeof size]: TypographyVariants;
  } = {
    small: 'labelTag',
    medium: 'labelXSmall',
  };

  return (
    <Box className={PNLStyles({ size })} {...props}>
      <Typography variant="labelTag">
        <NumberFormat
          digit={digit}
          type={value === null ? 'decimal' : 'percent'}
          value={value === null ? Infinity : value}
          signDisplay="always"
          colorBasedOnSign
          notSecret
          textVariant={MAP_SIZE_TO_TEXT_VARIANT[size]}
        />
      </Typography>
    </Box>
  );
};
