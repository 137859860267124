import * as React from 'react';

import { Box } from '../Box';
import { Icon, IconName } from '../Icon';
import { Tooltip } from '../Tooltip';
import { Typography } from '../Typography';

import { avatarStyles, AvatarVariant } from './Avatar.css';

const MAP_SIZE_TO_FONT_VARIANTS = {
  xsmall: 'bodyXXSmall',
  small: 'bodyXSmall',
  medium: 'bodyXSmall',
  large: 'bodySmall',
  xlarge: 'bodyMedium',
} as const;

const MAP_SIZE_TO_ICON_SIZE = {
  xsmall: 'small',
  small: 'small',
  medium: 'medium',
  large: 'medium',
  xlarge: 'large',
} as const;

export type BaseAvatarProps = {
  name?: React.ReactNode;
  style?: React.CSSProperties;
  withoutTooltip?: boolean;
} & AvatarVariant &
  (
    | {
        initials: string;
        icon?: never;
      }
    | {
        icon: IconName;
        initials?: never;
      }
  );

export type AvatarWithInitialsProps = {
  initials: string;
  icon?: never;
} & BaseAvatarProps;

export type AvatarWithIconProps = {
  icon: IconName;
  initials?: never;
} & BaseAvatarProps;

export type AvatarProps = AvatarWithInitialsProps | AvatarWithIconProps;

export const Avatar = ({
  name,
  initials,
  variant,
  size = 'xsmall',
  style,
  withoutTooltip,
  icon,
}: AvatarProps) => {
  const avatarContent = icon ? (
    <Box
      className={avatarStyles({ variant, size, withoutTooltip })}
      style={style}
    >
      {
        <Icon
          name={icon}
          size={MAP_SIZE_TO_ICON_SIZE[size]}
          color={
            ['gradient', 'primary'].includes(variant as string)
              ? 'inverse'
              : 'primary'
          }
        />
      }
    </Box>
  ) : (
    <Typography
      variant={MAP_SIZE_TO_FONT_VARIANTS[size]}
      className={avatarStyles({ variant, size, withoutTooltip })}
      style={style}
    >
      {initials}
    </Typography>
  );

  const iconContent = icon ? (
    <Box className={avatarStyles({ variant, size, withoutTooltip })}>
      <Icon name={icon} size={MAP_SIZE_TO_ICON_SIZE[size]} />
    </Box>
  ) : null;

  if (withoutTooltip) {
    return avatarContent;
  }

  return (
    <Tooltip
      size="small"
      title={name}
      usePortal
      contentProps={{ sideOffset: 4 }}
    >
      {icon ? iconContent : avatarContent}
    </Tooltip>
  );
};
