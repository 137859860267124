import classNames from 'classnames';

import { Box, BoxProps } from '../Box';

import { CardRecipeVariants, cardStyles } from './Card.css';

export type CardProps<C extends React.ElementType> = BoxProps<C> &
  CardRecipeVariants;

export const Card = <C extends React.ElementType = 'div'>({
  className,
  variant,
  ...props
}: CardProps<C>) => {
  return (
    <Box
      padding="s6"
      borderRadius="medium"
      flexDirection="column"
      className={classNames(cardStyles({ variant }), className)}
      {...(props as BoxProps<C>)}
    />
  );
};
