import * as React from 'react';

import { Icon, IconProps } from '../Icon';

import { copyIconStyles } from './CopyIcon.css';

export type CopyIconProps = Pick<IconProps, 'size' | 'color'> & {
  feedback: 'success' | 'error' | undefined;
  lastFeedback: React.MutableRefObject<Date | undefined>;
};

export const CopyIcon = ({
  feedback,
  lastFeedback,
  ...props
}: CopyIconProps) => {
  const icon = feedback ? (feedback === 'success' ? 'check' : 'close') : 'copy';

  return (
    <Icon
      key={icon}
      className={lastFeedback.current ? copyIconStyles.iconIn : undefined}
      name={icon}
      size="medium"
      {...props}
    />
  );
};
