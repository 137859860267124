import { Box } from '../Box';
import { InfoCircleTooltip } from '../InfoCircleTooltip';
import { Typography } from '../Typography';

export type InfoCardProps = {
  children: React.ReactNode;
  title: string;
  tooltipTitle: string;
};

export const InfoCard = ({ children, title, tooltipTitle }: InfoCardProps) => (
  <Box
    flex={1}
    padding="s5"
    flexDirection="column"
    gap="s6"
    background="secondary"
    borderRadius="medium"
  >
    <Box gap="s2" alignItems="center">
      <Typography
        variant="bodyMedium"
        color="secondary"
        lineClamp={1}
        showTooltipWhenOverflowing
      >
        {title}
      </Typography>
      <InfoCircleTooltip
        title={tooltipTitle}
        color="tertiary"
        contentProps={{ sideOffset: 4 }}
      />
    </Box>
    {children}
  </Box>
);
