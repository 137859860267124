import * as React from 'react';

export type IndicatorPosition = {
  height: number;
  width: number;
  x: number;
  y: number;
};

export const useSelectedIndicator = <T,>(
  containerRef: React.RefObject<HTMLDivElement>,
  getElementByValue: (value?: T) => Element | null,
  setIndicatorAnimation: (animate: boolean) => void,
  setIndicatorPosition: (position: IndicatorPosition) => void,
  initialState?: T,
  selected?: T
) => {
  const lastIndicator = React.useRef<IndicatorPosition>();

  // Update the selected indicator to match the given element
  // position / dimensions
  const updateIndicator = React.useCallback(
    (selectedElement: Element) => {
      const parent = containerRef.current;
      if (parent) {
        const parentRect = parent.getBoundingClientRect();
        const rect = selectedElement.getBoundingClientRect();
        const indicator: IndicatorPosition = {
          x: rect.x - parentRect.x,
          y: rect.y - parentRect.y,
          width: rect.width,
          height: rect.height,
        };
        setIndicatorPosition(indicator);
        lastIndicator.current = indicator;
      }
    },
    [containerRef, setIndicatorPosition]
  );

  // When mounting we want to position the indicator to its latest
  // position if we already had one, otherwise, we want to prevent animations
  // so the initial render isn't animated
  React.useEffect(() => {
    const selectedChip = getElementByValue(initialState);
    if (lastIndicator.current) {
      setIndicatorPosition(lastIndicator.current);
    } else if (selectedChip) {
      setIndicatorAnimation(false);
      updateIndicator(selectedChip);
      setTimeout(() => {
        setIndicatorAnimation(true);
      });
    }
  }, [
    getElementByValue,
    initialState,
    setIndicatorPosition,
    setIndicatorAnimation,
    updateIndicator,
  ]);

  // Observe when the element resizes and update selected indicator
  // size / position
  React.useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    let timeout: ReturnType<typeof setTimeout>;
    const resizeObserver = new ResizeObserver(() => {
      const selectedChip = getElementByValue(selected);
      if (selectedChip) {
        clearTimeout(timeout);
        setIndicatorAnimation(false);
        updateIndicator(selectedChip);
        timeout = setTimeout(() => {
          setIndicatorAnimation(true);
        }, 125);
      }
    });
    resizeObserver.observe(containerRef.current);

    return () => resizeObserver.disconnect();
  }, [
    selected,
    getElementByValue,
    updateIndicator,
    containerRef,
    setIndicatorAnimation,
  ]);
  return updateIndicator;
};
