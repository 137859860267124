import { Tag, Size } from '../Tag';
import { TAG_SIZE_HEIGHT_MAP } from '../Tag/Tag.css';

export type KeyProps = {
  size?: Size;
};

export const Key = ({
  size = 'small',
  children,
}: React.PropsWithChildren<KeyProps>) => {
  return (
    <Tag
      size={size}
      style={{ minWidth: TAG_SIZE_HEIGHT_MAP[size], alignSelf: 'start' }}
    >
      <kbd
        style={{
          fontWeight: 'inherit',
          fontSize: 'inherit',
          fontFamily: 'inherit',
          fontStyle: 'inherit',
        }}
      >
        {children}
      </kbd>
    </Tag>
  );
};
