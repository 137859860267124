import { DialogProps } from '@radix-ui/react-dialog';
import * as React from 'react';

import {
  StepSideModalNavigateTo,
  StepSideModalNavigationProvider,
  StepSideModalParamsList,
} from '../../providers/StepSideModalNavigationProvider';
import { StepSideModalParamsProvider } from '../../providers/StepSideModalParamsProvider';
import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { SideModal, SideModalContentProps } from '../SideModal';

import { stepSideModalStyles } from './StepSideModal.css';

export type StepSideModalSteps<T extends StepSideModalParamsList> =
  StepSideModalProps<T, keyof T>['steps'];

type Step<Key, Params extends object | undefined> = {
  component: () => React.ReactNode;
  key: Key;
  description?: React.ReactNode;
  headerChildren?: React.ReactNode;
  headerTabs?: React.ReactNode;
  params?: Params;
  previousStepKey?: Key;
  title?: React.ReactNode;
};

export type StepSideModalRootProps = Pick<DialogProps, 'open' | 'onOpenChange'>;

export type StepSideModalProps<
  T extends StepSideModalParamsList,
  Key extends keyof T
> = {
  steps: Step<Key, T[Key]>[];
  children?: React.ReactNode;
  onNavigateToStep?: (key?: keyof T) => void;
  resetNavigationOnClose?: boolean;
} & Pick<SideModalContentProps, 'preventContentScroll'> &
  StepSideModalRootProps;

export const StepSideModal = <T extends StepSideModalParamsList>({
  children,
  steps,
  preventContentScroll,
  open,
  onOpenChange,
  onNavigateToStep,
  resetNavigationOnClose,
}: StepSideModalProps<T, keyof T>) => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [currentStep, setCurrentStep] = React.useState(steps[0]);

  React.useEffect(() => {
    setCurrentStep(steps[0]);
  }, [steps]);

  if (!currentStep) {
    return null;
  }

  const currentIndex = steps.findIndex((step) => step.key === currentStep.key);

  const navigateTo: StepSideModalNavigateTo<T> = async (key, params) => {
    const newStep = steps.find((step) => step.key === key);

    if (newStep) {
      resetScroll();

      /**
       * The await here is needed in case of a parent component using onNavigateToStep
       * to set a scroll position.
       * It behave as if we were using `setTimeout, 0` except that the async / await solution
       * prevents from having a glitch in the scroll (top first, then scroll)
       */
      await setCurrentStep({
        ...newStep,
        title: params?.title ?? newStep.title,
        description: params?.description ?? newStep.description,
        params: params?.params ?? newStep.params,
      });

      onNavigateToStep?.(key);
    }
  };

  const resetScroll = () => {
    contentRef.current?.scrollTo({
      top: 0,
    });
  };

  return (
    <StepSideModalParamsProvider params={currentStep.params}>
      <StepSideModalNavigationProvider<T> navigateTo={navigateTo}>
        <SideModal.Root
          open={open}
          onOpenChange={(value) => {
            if (!value && resetNavigationOnClose) {
              navigateTo(steps[0]?.key);
            }
            onOpenChange?.(value);
          }}
        >
          <SideModal.Content
            childContainerRef={contentRef}
            title={currentStep.title}
            description={currentStep.description}
            headerChildren={currentStep.headerChildren}
            headerTabs={currentStep.headerTabs}
            preventContentScroll={preventContentScroll}
            headerPrefixIcon={
              currentStep.key !== steps[0]?.key ? (
                <IconButton
                  icon="chevronLeft"
                  size="small"
                  variant="secondary"
                  onClick={() =>
                    navigateTo(
                      currentStep.previousStepKey ??
                        steps[currentIndex > 0 ? currentIndex - 1 : 0]?.key
                    )
                  }
                />
              ) : undefined
            }
          >
            {steps.map((step, index) => {
              const Component = step.component;

              if (index > currentIndex) {
                return null;
              }

              const shouldBeHidden = index < currentIndex;

              return (
                <Box
                  className={
                    shouldBeHidden
                      ? stepSideModalStyles.hiddenContainer
                      : undefined
                  }
                  flexDirection="column"
                  flexGrow={1}
                  key={step.key.toString()}
                >
                  <Component />
                </Box>
              );
            })}
          </SideModal.Content>
          {children ? (
            <SideModal.Trigger asChild>{children}</SideModal.Trigger>
          ) : undefined}
        </SideModal.Root>
      </StepSideModalNavigationProvider>
    </StepSideModalParamsProvider>
  );
};
