import { theme } from '../../styles/theme.css';
import { Box } from '../Box';
import { Icon, IconProps } from '../Icon';

export type StarsRatingProps = {
  maxStars: number;
  rating: number;
  onChange?: (rating: number) => void;
  size?: IconProps['size'];
};

export const StarsRating = ({
  rating,
  maxStars,
  size = 'large',
  onChange,
}: StarsRatingProps) => {
  return (
    <Box>
      {[...new Array(maxStars)].map((_, index) => {
        const icon = (
          <Icon
            key={index}
            size={size}
            name="starSolid"
            style={{
              color:
                index < rating
                  ? theme.color.surface.accentPrimary
                  : theme.color.surface.tertiary,
            }}
          />
        );

        return onChange ? (
          <button key={index} onClick={() => onChange(index + 1)} type="button">
            {icon}
          </button>
        ) : (
          icon
        );
      })}
    </Box>
  );
};
