import * as React from 'react';

import { Sprinkles } from '../../styles/sprinkles.css';
import { Box } from '../Box';
import { ChipInput, ChipInputProps } from '../Chip/ChipInput';

export type ChipInputGroupChipProps = Omit<ChipInputProps, 'type'> & {
  key: string;
};

export type ChipInputGroupProps = Omit<
  React.ComponentPropsWithoutRef<'input'>,
  'size' | 'type'
> & {
  chips: ChipInputGroupChipProps[];
  direction: Sprinkles['flexDirection'];
  multiple?: boolean;
  size?: ChipInputProps['size'];
};

export const ChipInputGroup = React.forwardRef<
  HTMLInputElement,
  ChipInputGroupProps
>(({ direction, chips, multiple, size, ...rest }, ref) => {
  return (
    <Box flexDirection={direction} gap="s3" flexWrap="wrap">
      {chips.map((chip) => {
        const { key, ...chipProps } = chip;
        return (
          <ChipInput
            ref={ref}
            key={key}
            type={multiple ? 'checkbox' : 'radio'}
            size={size}
            {...chipProps}
            {...rest}
          />
        );
      })}
    </Box>
  );
});

ChipInputGroup.displayName = 'ChipInputGroup';
