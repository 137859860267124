import { Box, BoxProps } from '../Box';

export type IllustrationAssetProps = BoxProps & {
  size: number;
  src: string;
  alt?: string;
};

/**
 * Component used to display illustration assets with correct padding.
 * The size is the width and height of the image container.
 */
export const IllustrationAsset = ({
  alt,
  size,
  style,
  src,
  ...props
}: IllustrationAssetProps) => {
  return (
    <Box
      style={{
        width: size,
        height: size,
        minHeight: size,
        minWidth: size,
        maxHeight: size,
        maxWidth: size,
        padding: (size / 104) * 16, // for a 104px size, we want 16px padding, then it's linear
        ...style,
      }}
      {...props}
    >
      <img
        style={{
          width: '100%',
          height: '100%',
        }}
        src={src}
        alt={alt}
      />
    </Box>
  );
};
