import * as React from 'react';

import { theme } from '../../styles/theme.css';
import { Box } from '../Box';
import { Icon, IconName } from '../Icon';
import { Modal, ModalWidth } from '../Modal';
import { Separator } from '../Separator';
import { Typography } from '../Typography';

export type ReassuranceBlockData<T extends string> = {
  description: string;
  icon: IconName;
  key: T;
  text: React.ReactNode;
  title: string;
};

export type ReassuranceBlockProps = {
  data: ReassuranceBlockData<string>[];
  modalTitle: string;
};

export const ReassuranceBlock = ({
  data,
  modalTitle,
}: ReassuranceBlockProps) => {
  return (
    <ReassuranceBlockModal data={data} modalTitle={modalTitle}>
      <Box
        as="button"
        alignItems="center"
        padding="s5"
        borderRadius="medium"
        gap="s5"
        style={{
          border: `1px solid ${theme.color.border.overlay}`,
        }}
      >
        {data.map(({ description, icon, title, key }) => (
          <ReassuranceInnerBlock
            key={key}
            description={description}
            icon={icon}
            title={title}
          />
        ))}
      </Box>
    </ReassuranceBlockModal>
  );
};

const ReassuranceInnerBlock = ({
  title,
  description,
  icon,
}: Pick<ReassuranceBlockData<string>, 'description' | 'icon' | 'title'>) => {
  return (
    <Box flex={1} gap="s3" alignItems="center">
      <Box
        alignItems="center"
        borderRadius="medium"
        justifyContent="center"
        style={{
          height: 40,
          width: 40,
          border: `1px solid ${theme.color.border.overlay}`,
        }}
      >
        <Icon name={icon} color="success" />
      </Box>
      <Box flexDirection="column" gap="s1">
        <Typography
          variant="labelMedium"
          lineClamp={1}
          showTooltipWhenOverflowing
        >
          {title}
        </Typography>
        <Typography variant="bodySmall" color="tertiary">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

type ReassuranceBlockModalProps =
  React.PropsWithChildren<ReassuranceBlockProps>;

export const ReassuranceBlockModal = ({
  data,
  modalTitle,
  children,
}: ReassuranceBlockModalProps) => {
  return (
    <Modal.Root>
      <Modal.ContentScrollable title={modalTitle} width={ModalWidth.LARGE}>
        <Box flexDirection="column" gap="s6">
          {data.map(({ description, icon, text, title }, index) => (
            <React.Fragment key={index}>
              <Box flexDirection="column" gap="s5">
                <ReassuranceInnerBlock
                  description={description}
                  icon={icon}
                  title={title}
                />
                <Typography variant="bodyMedium" color="secondary">
                  {text}
                </Typography>
              </Box>
              {index !== data.length - 1 ? (
                <Separator variant="horizontal" />
              ) : null}
            </React.Fragment>
          ))}
        </Box>
      </Modal.ContentScrollable>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
    </Modal.Root>
  );
};
