import { formatNumber } from 'utils';

import { useUIState } from '../providers/UIProvider';

export const useFormatPercentsYAxis = () => {
  const { isSecretModeEnabled, locale } = useUIState();

  return {
    formatPercentYAxis: (tickValue: number) => {
      if (isSecretModeEnabled) {
        return '••••';
      }

      return formatNumber(tickValue / 100, locale, {
        style: 'percent',
      });
    },
  };
};
