import {
  useId,
  useEffect,
  useCallback,
  useState,
  useRef,
  ReactNode,
} from 'react';

import { hideScrollbarStyles } from '../../styles/hideScrollbar.css';
import { breatheAnimation } from '../../styles/reset.css';
import { Box } from '../Box';
import { IconButton } from '../IconButton';

import { carouselStyle } from './Carousel.css';

export type CarouselProps = {
  header: ReactNode;
  disabled?: boolean;
  emptyComponent?: ReactNode;
  isLoading?: boolean;
  items?: ReactNode[];
  loadingItemComponent?: ReactNode;
  loadingItemCount?: number;
  onScrollToNext?: () => void;
  onScrollToPrev?: () => void;
};

export const Carousel = ({
  emptyComponent,
  items,
  isLoading,
  disabled,
  header,
  loadingItemCount = 10,
  loadingItemComponent,
  onScrollToPrev,
  onScrollToNext,
}: CarouselProps) => {
  const carouselId = useId();
  const [prevButton, setPrevButton] = useState<boolean>(false);
  const [nextButton, setNextButton] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const isEmpty = !items || !items?.length;
  const disableButtons = isLoading || disabled;

  // Scrolls the container to previous slide until hitting 0
  const handleScrollToPrev = () => {
    const container = containerRef.current;
    if (container && container.scrollLeft > 0) {
      container.scrollLeft -= container.offsetWidth;
      onScrollToPrev?.();
    }
  };

  // Scrolls the container to next slide until hitting max
  const handleScrollToNext = () => {
    const container = containerRef.current;
    if (container && container.scrollLeft < container.scrollWidth) {
      container.scrollLeft += container.offsetWidth;
      onScrollToNext?.();
    }
  };

  // Update buttons based on remaining scroll distance
  const updateButtons = useCallback(() => {
    const container = containerRef.current;
    if (!container || container.scrollWidth <= container.offsetWidth) {
      setPrevButton(false);
      setNextButton(false);
    } else if (container.scrollLeft <= 0) {
      setPrevButton(false);
      setNextButton(true);
    } else if (
      container.scrollLeft <
      container.scrollWidth - container.offsetWidth
    ) {
      setPrevButton(true);
      setNextButton(true);
    } else {
      setPrevButton(true);
      setNextButton(false);
    }
  }, []);

  useEffect(() => {
    // Make sure to initialize the buttons
    updateButtons();
    window.addEventListener('resize', updateButtons);
    return () => window.removeEventListener('resize', updateButtons);
  }, [updateButtons]);

  return (
    <Box
      id={carouselId}
      as="section"
      flexDirection="column"
      style={{ width: '100%' }}
    >
      <Box
        as="header"
        gap="s5"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Box>{header}</Box>
        <Box
          alignItems="center"
          style={
            !items?.length || items?.length < 2
              ? { display: 'none' }
              : undefined
          }
        >
          <IconButton
            data-testid="carousel-prev-button"
            disabled={disableButtons || !prevButton}
            variant="quaternary"
            icon="chevronLeft"
            size="small"
            onClick={handleScrollToPrev}
          />
          <IconButton
            data-testid="carousel-next-button"
            disabled={disableButtons || !nextButton}
            variant="quaternary"
            icon="chevronRight"
            size="small"
            onClick={handleScrollToNext}
          />
        </Box>
      </Box>
      <Box
        data-testid="carousel-container"
        ref={containerRef}
        gap="s5"
        marginTop="s5"
        overflowX={disabled ? 'hidden' : 'auto'}
        className={[
          hideScrollbarStyles,
          isLoading ? breatheAnimation : undefined,
          carouselStyle.container,
        ]
          .filter(Boolean)
          .join(' ')}
        onScroll={updateButtons}
      >
        {isLoading
          ? [...new Array(loadingItemCount)].map((_, index) => (
              <Box
                key={`carousel-${carouselId}-loading-item-${index}`}
                className={carouselStyle.item}
              >
                {loadingItemComponent}
              </Box>
            ))
          : isEmpty
          ? emptyComponent
          : items.map((item, index) => {
              return (
                <Box
                  key={`carousel-${carouselId}-item-${index}`}
                  className={carouselStyle.item}
                >
                  {item}
                </Box>
              );
            })}
      </Box>
    </Box>
  );
};
