import React from 'react';

import { Sprinkles } from '../../styles/sprinkles.css';
import { Box, BoxProps } from '../Box';
import { Chip, ChipPolymorphicProps, ChipProps } from '../Chip';

export type ChipGroupChipProps<
  C extends React.ElementType,
  Key extends string
> = ChipPolymorphicProps<C> & { key: Key };

export type ChipGroupProps<
  C extends React.ElementType = 'button',
  Key extends string = string
> = {
  chips: ChipGroupChipProps<C, Key>[];
  boxProps?: BoxProps<'div'>;
  defaultValue?: Key;
  direction?: Sprinkles['flexDirection'];
  gap?: BoxProps<'div'>['gap'];
  onChange?: (selected: Key) => void;
  size?: ChipProps['size'];
  value?: Key;
  variant?: ChipProps['variant'];
};

export const typedChipConfig = <
  C extends React.ElementType,
  Key extends string
>(
  config: ChipGroupChipProps<C, Key>
): ChipGroupChipProps<C, Key> => {
  return config;
};

export const ChipGroup = <C extends React.ElementType, Key extends string>({
  direction = 'row',
  size = 'medium',
  variant,
  defaultValue,
  value,
  chips,
  onChange,
  gap = 's3',
  boxProps,
}: ChipGroupProps<C, Key>) => {
  const [selectedState, setSelectedState] = React.useState<Key | undefined>(
    defaultValue
  );

  return (
    <Box flexDirection={direction} gap={gap} flexWrap="wrap" {...boxProps}>
      {chips.map((chipSpecs) => {
        const { key, ...chipProps } = chipSpecs;
        const isSelected = value ? key === value : key === selectedState;

        const updateSelected = (key: Key) => {
          setSelectedState(key);
          onChange?.(key);
        };

        const handleClick: React.MouseEventHandler<HTMLElement> = (event) => {
          chipProps.onClick?.(event);
          updateSelected(key);
        };

        return (
          <Chip<'button'>
            key={key}
            size={size}
            variant={variant}
            {...chipProps}
            active={isSelected}
            onClick={handleClick}
          />
        );
      })}
    </Box>
  );
};
