import classNames from 'classnames';
import * as React from 'react';

import { useCombinedRefs } from '../../hooks/useCombinedRefs';
import { theme } from '../../styles/theme.css';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { Tooltip, TooltipProps } from '../Tooltip';
import { Typography } from '../Typography';

import {
  checkboxCheckContainerStyles,
  checkboxCheckIconStyles,
  checkboxContainerStyles,
  checkboxHyphenIconStyles,
  checkboxInputStyles,
  checkboxLabelStyles,
} from './Checkbox.css';

export type CheckboxProps = React.ComponentProps<'input'> & {
  name: string;
  indeterminate?: boolean;
  label?: React.ReactNode;

  tooltip?: Pick<TooltipProps, 'title' | 'description' | 'size' | 'usePortal'>;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      className,
      tooltip,
      style,
      required = false,
      indeterminate,
      ...props
    },
    ref
  ) => {
    const innerRef = React.useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs(ref, innerRef);

    React.useEffect(() => {
      if (innerRef.current) {
        innerRef.current.indeterminate = !!indeterminate;
      }
    }, [indeterminate]);

    return (
      <Box flexDirection="column" style={style}>
        <Box
          as="label"
          className={classNames(checkboxContainerStyles, className)}
          data-disabled={props.disabled}
          gap="s3"
        >
          <Box>
            <input
              {...props}
              ref={combinedRef}
              id={props.name}
              type="checkbox"
              required={required}
              className={checkboxInputStyles}
            />
            <Box
              className={checkboxCheckContainerStyles}
              alignItems="center"
              justifyContent="center"
            >
              <Icon
                name="check"
                size="small"
                className={checkboxCheckIconStyles}
              />
              <Icon
                name="hyphen"
                size="small"
                className={checkboxHyphenIconStyles}
              />
            </Box>
          </Box>

          {label ? (
            <Typography
              as="label"
              htmlFor={props.name}
              className={checkboxLabelStyles}
              color="tertiary"
            >
              {label}
              {tooltip ? (
                <Tooltip
                  size={tooltip.size || 'small'}
                  title={tooltip.title}
                  description={tooltip.description}
                  usePortal={tooltip.usePortal}
                  contentProps={{
                    side: 'top',
                    sideOffset: 4,
                    style: { maxWidth: 220 },
                  }}
                >
                  <IconButton
                    size="xsmall"
                    icon="infoCircle"
                    color={theme.color.text.tertiary}
                    type="button"
                  />
                </Tooltip>
              ) : null}
            </Typography>
          ) : null}
        </Box>
      </Box>
    );
  }
);

Checkbox.displayName = 'Checkbox';
