import React from 'react';

import { Box, BoxProps } from '../Box';
import { Icon } from '../Icon';

import { imageWrapperStyle, ImageWrapperVariant } from './ImageWrapper.css';

export type ImageWrapperProps = {
  fallback?: string | React.ReactNode;
  height?: string | number;
  /**
   * If true, the fallback will not be displayed.
   * Can be useful when rendering a static page
   */
  noFallback?: boolean;
  src?: string | null;
  width?: string | number;
} & BoxProps &
  ImageWrapperVariant;

// To prevent the loaded event async delay before showing the image, we keep
// track of the `src`s we already have been loading, so we can completely skip
// displaying the placeholder image when the image already has been cached
const loadedImages = new Map<string, true>();

export const ImageWrapper = ({
  src,
  fallback = <Icon name="finary-mark" color="tertiary" size="large" />,
  height = '100%',
  width = '100%',
  style,
  variant,
  noFallback,
  ...props
}: ImageWrapperProps) => {
  const imgRef = React.useRef<HTMLImageElement>(null);
  const loaded = noFallback ? true : src ? loadedImages.get(src) : false;
  const fallbackIsString = typeof fallback === 'string';

  return (
    <Box
      key={src}
      {...props}
      alignItems="center"
      justifyContent="center"
      className={imageWrapperStyle.container}
      style={{
        backgroundSize: 'cover',
        backgroundImage:
          !loaded && fallbackIsString ? `url(${fallback})` : undefined,
        overflow: 'hidden',
        objectFit: 'contain',
        minWidth: width,
        maxWidth: width,
        width: width,
        minHeight: height,
        maxHeight: height,
        height: height,
        ...style,
      }}
    >
      {fallbackIsString || loaded ? undefined : fallback}
      {src ? (
        <img
          className={imageWrapperStyle.image({ variant })}
          ref={imgRef}
          alt=""
          src={src}
          style={{
            opacity: loaded ? 1 : 0,
            maxWidth: width,
            maxHeight: height,
          }}
          onError={
            loaded
              ? undefined
              : ({ currentTarget }) => {
                  currentTarget.style.setProperty('opacity', '0');
                }
          }
          onLoad={
            loaded
              ? undefined
              : ({ currentTarget }) => {
                  loadedImages.set(src, true);
                  currentTarget.style.setProperty('opacity', '1');
                  currentTarget.parentElement?.style.setProperty(
                    'background-image',
                    'none'
                  );
                }
          }
        />
      ) : null}
    </Box>
  );
};
