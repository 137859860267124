import { useCopyValue } from '../../hooks/useCopyValue';
import { Box } from '../Box';
import { CopyIcon } from '../CopyIcon';
import { IconProps } from '../Icon';
import { Typography, TypographyProps } from '../Typography';

import { copyableStyles } from './Copyable.css';

export type CopyableProps = {
  value: string;
  iconColor?: IconProps['color'];
  iconSize?: IconProps['size'];
} & Pick<TypographyProps<'button'>, 'variant' | 'color' | 'onClick'>;

export const Copyable = ({
  value,
  variant,
  color,
  iconColor,
  iconSize = 'small',
  onClick,
}: CopyableProps) => {
  const { feedback, handleCopyValue, lastFeedback } = useCopyValue(value);

  if (!value) {
    return null;
  }

  return (
    <Typography
      key={value}
      as="button"
      variant={variant ?? 'bodySmall'}
      color={color ?? 'tertiary'}
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClick?.(event);
        handleCopyValue(event);
      }}
      className={copyableStyles.button}
    >
      <Box alignItems="center" gap="s2">
        {value}
        <CopyIcon
          feedback={feedback}
          lastFeedback={lastFeedback}
          size={iconSize}
          color={iconColor}
        />
      </Box>
    </Typography>
  );
};
