import * as React from 'react';

import {
  StepSideModalNavigateTo,
  StepSideModalNavigationContext,
  StepSideModalParamsList,
} from '../providers/StepSideModalNavigationProvider';

export const useStepSideModalNavigation = <
  T extends StepSideModalParamsList
>() => {
  const context = React.useContext<StepSideModalNavigateTo<T>>(
    StepSideModalNavigationContext as unknown as React.Context<
      StepSideModalNavigateTo<T>
    >
  );

  if (context === undefined) {
    throw new Error(
      'useStepSideModalNavigation must be used within StepSideModalNavigationProvider'
    );
  }

  return context;
};
