import { Box } from '../Box';
import { Button } from '../Button';
import { IllustrationAsset } from '../IllustrationAsset';
import { Tag, TagProps } from '../Tag';
import { Typography } from '../Typography';

import { emptyStateStyles } from './EmptyState.css';

type EmptyStateProps = {
  title: React.ReactNode;
  alt?: string;
  body?: string;
  header?: React.ReactNode;
  hideIllustration?: boolean;
  isIllustrationAsset?: boolean;
  primaryButtonProps?: React.ComponentProps<typeof Button>;
  secondaryButtonProps?: Omit<React.ComponentProps<typeof Button>, 'variant'>;
  size?: keyof typeof IMAGE_SIZE;
  src?: string;
  style?: React.CSSProperties;
  tagProps?: TagProps;
};

const IMAGE_SIZE = {
  xsmall: 48,
  small: 80,
  medium: 92,
  large: 128,
};

export const EmptyState = ({
  title,
  body,
  primaryButtonProps,
  secondaryButtonProps,
  tagProps,
  size = 'medium',
  src = '',
  alt = '',
  isIllustrationAsset,
  style,
  hideIllustration,
  header,
}: EmptyStateProps) => {
  return (
    <Box
      flexDirection="column"
      paddingHorizontal="s8"
      paddingVertical="s7"
      alignSelf="center"
      textAlign="center"
      justifyContent="center"
      alignItems="center"
      className={emptyStateStyles.container}
      gap="s6"
      style={style}
    >
      <Box
        gap="s5"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {hideIllustration ? null : isIllustrationAsset ? (
          <IllustrationAsset src={src} alt={alt} size={IMAGE_SIZE[size]} />
        ) : (
          <img
            src={src}
            width={IMAGE_SIZE[size]}
            height={IMAGE_SIZE[size]}
            alt={alt}
          />
        )}
        {header}
        <Box flexDirection="column" gap="s3">
          <Typography
            variant={
              size === 'large'
                ? 'displaySmall'
                : size === 'medium'
                ? 'headingLarge'
                : size === 'xsmall'
                ? 'headingSmall'
                : 'headingMedium'
            }
            as="h1"
            className={emptyStateStyles.header}
          >
            {title}
          </Typography>

          {body ? (
            <Typography
              variant={size === 'large' ? 'bodyLarge' : 'bodyMedium'}
              color="secondary"
            >
              {body}
            </Typography>
          ) : null}
        </Box>
      </Box>

      {tagProps ? <Tag {...tagProps} /> : null}
      <Box
        flexDirection="column"
        gap="s5"
        className={emptyStateStyles.buttonContainer}
      >
        {primaryButtonProps ? (
          <Button
            variant="primary"
            className={emptyStateStyles.button({ size })}
            {...primaryButtonProps}
          />
        ) : null}

        {secondaryButtonProps ? (
          <Button
            variant="secondary"
            className={emptyStateStyles.button({ size })}
            {...secondaryButtonProps}
          />
        ) : null}
      </Box>
    </Box>
  );
};
