import * as React from 'react';

export const isElementType = (what: unknown, type: React.ElementType) => {
  if (what && typeof what === 'object') {
    const whatType = (what as React.ReactElement).type;
    if (whatType === type) {
      return true;
    }
  }

  return false;
};
