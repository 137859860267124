import * as React from 'react';

export const useFeedback = <T extends string>() => {
  const [feedback, setFeedback] = React.useState<T | undefined>();
  const feedbackTimoutRef = React.useRef<ReturnType<typeof setTimeout>>();
  const lastFeedback = React.useRef<Date | undefined>();

  const setNewFeedback = React.useCallback((feedback: T | undefined) => {
    if (feedbackTimoutRef.current) {
      clearTimeout(feedbackTimoutRef.current);
    }
    lastFeedback.current = new Date();

    setFeedback(feedback);
    feedbackTimoutRef.current = setTimeout(() => {
      setFeedback(undefined);
      setTimeout(() => {
        lastFeedback.current = undefined;
      });
    }, 3000);
  }, []);

  return [feedback, setNewFeedback, lastFeedback] as const;
};
