import { Box } from '../Box';
import { Icon } from '../Icon';
import { ImageWrapper } from '../ImageWrapper';
import { Tag } from '../Tag';
import { TagVariant } from '../Tag/Tag.css';
import { Typography } from '../Typography';

import { articleCardStyles } from './ArticleCard.css';

export type ArticleContent = {
  description: string;
  title: string;
  href?: string;
  tagText?: string;
  tagVariant?: Exclude<TagVariant, undefined>['variant'];
  thumbnailSrc?: string;
};

export type ArticleCardProps = {
  article: ArticleContent;
};

export const ArticleCard = ({ article }: ArticleCardProps) => {
  const { description, title, thumbnailSrc, href, tagText, tagVariant } =
    article;

  return (
    <Box
      as="a"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={articleCardStyles.container}
    >
      <Box className={articleCardStyles.previewContainer}>
        <ImageWrapper src={thumbnailSrc} />
        <Box className={articleCardStyles.gradientOverlay} />

        {tagText ? (
          <Tag
            className={articleCardStyles.tag}
            variant={tagVariant}
            size="small"
          >
            {tagText}
          </Tag>
        ) : null}
      </Box>
      <Box flexDirection="column" gap="s2">
        <Box alignItems="center" gap="s2">
          <Typography
            variant="bodyMediumEmphasis"
            color="primary"
            lineClamp={1}
          >
            {title}
          </Typography>
          <Icon name="externalLink" size="small" color="tertiary" />
        </Box>

        <Typography variant="bodyMedium" color="tertiary">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};
