import classNames from 'classnames';
import * as React from 'react';

import { Box, BoxProps } from '../Box';
import { Icon } from '../Icon';

import {
  searchBarStyle,
  SearchBarVariants,
  clearButtonStyle,
  searchIconStyle,
} from './SearchBar.css';

export type SearchBarProps = {
  onChange: (value: string) => void;
  boxProps?: BoxProps;
  suffix?: React.ReactNode;
} & SearchBarVariants &
  Pick<
    React.ComponentProps<'input'>,
    | 'name'
    | 'placeholder'
    | 'autoFocus'
    | 'value'
    | 'onFocus'
    | 'onBlur'
    | 'disabled'
  >;

export const SearchBar = React.forwardRef<HTMLInputElement, SearchBarProps>(
  (
    {
      variant = 'primary',
      value,
      size = 'large',
      disabled,
      onChange,
      boxProps,
      suffix,
      ...props
    },
    ref?
  ) => {
    const iconSize = size === 'large' ? 'medium' : 'small';

    return (
      <Box
        className={classNames(
          searchBarStyle.container({ size, variant }),
          disabled ? searchBarStyle.disabledContainer : undefined
        )}
        {...boxProps}
      >
        <Icon className={searchIconStyle} name="search" size={iconSize} />
        <input
          ref={ref}
          disabled={disabled}
          className={searchBarStyle.input({ size })}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          {...props}
        />
        {suffix}
        {variant === 'primary' &&
        typeof value === 'string' &&
        value.length > 0 ? (
          <Box
            className={clearButtonStyle}
            borderRadius="small"
            as="button"
            type="button"
            onClick={() => onChange('')}
          >
            <Icon name="close" color="primary" size={iconSize} />
          </Box>
        ) : null}
      </Box>
    );
  }
);

SearchBar.displayName = 'SearchBar';
