import * as React from 'react';

import { theme } from '../../styles/theme.css';
import { Box } from '../Box';
import { Card } from '../Card';
import { InfoCircleTooltip } from '../InfoCircleTooltip';
import { Separator } from '../Separator';
import { Skeleton } from '../Skeleton';
import { FinaryPlusTag, Tag } from '../Tag';
import { Typography } from '../Typography';

export type DataCardBaseProps = {
  label: React.ReactNode;
  noDataLabel: string;
  // if you want to control the content, pass this props instead of datum
  customContent?: React.ReactNode;
  datum?: React.ReactNode;
  footer?: React.ReactNode;
  isLoading?: boolean;
  onClick?: React.MouseEventHandler;
  tooltip?: React.ReactNode;
};

export type DataCardPropsRestricted = DataCardBaseProps & {
  hasPlusAccess: boolean;
  plusOnly: true;
};

export type DataCardPropsUnrestricted = DataCardBaseProps & {
  hasPlusAccess?: never;
  plusOnly?: never;
};

export type DataCardProps = DataCardPropsRestricted | DataCardPropsUnrestricted;

const FAKE_DATA_WHEN_NOT_ALLOWED = '×8.4';

export const DataCard = ({
  customContent,
  label,
  tooltip,
  datum,
  footer,
  isLoading,
  plusOnly,
  hasPlusAccess,
  onClick,
  noDataLabel,
}: DataCardProps) => {
  const isAllowed = !plusOnly || hasPlusAccess;
  const isWithoutData = !isLoading && !datum;

  const labelNode = (
    <Typography variant="labelCaption" color="secondary">
      {label}
    </Typography>
  );

  const header = (
    <Box as="header" gap="s3" justifyContent="space-between">
      {labelNode}
      {tooltip ? (
        <InfoCircleTooltip title={tooltip} color="tertiary" iconSize="xsmall" />
      ) : null}
    </Box>
  );

  const content = isWithoutData ? (
    <DataCardNoDataTag noDataLabel={noDataLabel} />
  ) : (
    <DataCardText isAllowed={isAllowed}>
      {isAllowed ? datum : FAKE_DATA_WHEN_NOT_ALLOWED}
    </DataCardText>
  );

  return (
    <Card
      onClick={onClick}
      gap="s6"
      flex={1}
      style={{
        minWidth: 250,
        flexBasis: `calc(100% / 3 - ${theme.spacing.s6} * 2)`,
        padding: 0,
        cursor: isAllowed ? 'default' : 'pointer',
      }}
    >
      <Box
        gap="s4"
        paddingTop="s6"
        paddingHorizontal="s6"
        justifyContent="space-between"
        alignItems="center"
        style={{ minHeight: theme.spacing.s6 }}
      >
        {header}
        {!isAllowed ? <FinaryPlusTag /> : null}
      </Box>
      <Box
        gap="s4"
        paddingHorizontal="s6"
        paddingBottom={footer ? undefined : 's6'}
        flex={1}
      >
        {isLoading && !customContent ? (
          <DataCardContentLoader />
        ) : (
          customContent ?? content
        )}
      </Box>
      {footer ? (
        <Box flexDirection="column">
          <Separator variant="horizontal" />
          <Box marginVertical="s5" marginHorizontal="s6" style={{ height: 24 }}>
            {footer}
          </Box>
        </Box>
      ) : null}
    </Card>
  );
};

export const DataCardNoDataTag = ({
  noDataLabel,
}: Pick<DataCardBaseProps, 'noDataLabel'>) => {
  return (
    <Tag variant="default" size="medium">
      {noDataLabel}
    </Tag>
  );
};

export const DataCardContentLoader = () => (
  <Skeleton width={80} height={24} radius={4} />
);

export const DataCardText = ({
  isAllowed,
  children,
}: {
  children: React.ReactNode;
  isAllowed: boolean;
}) => (
  <Typography
    variant="headingSmall"
    style={{ width: '100%' }}
    color={!isAllowed ? 'secondary' : undefined}
    filter={!isAllowed ? 'blurSmall' : undefined}
  >
    {children}
  </Typography>
);
